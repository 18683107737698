import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactSVG } from 'react-svg'
import importer from '@takedapdt/biolife-core/src/importer'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getCssColorFromDrupalColorType, setTextColor } from '../../../Constant/Utils'
/*
Icons
*/
import { ReactComponent as CheckMarkIconOne } from '../../../../assets/media/icons/number-1-icon-fpo.svg'
import { ReactComponent as CheckMarkIconTwo } from '../../../../assets/media/icons/number-2-icon-fpo.svg'
import { ReactComponent as CheckMarkIconThree } from '../../../../assets/media/icons/number-3-icon-fpo.svg'
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const publicContentActions = importer('Stores/PublicContent/Actions')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'
const CustomModal = importer('Components/CustomModal')
const { renderModalComponent } = importer('Constant/Utils', null)
const TopEarningActivityContainer = styled.div`
  background-color: ${(props) => getCssColorFromDrupalColorType(props?.$topEarningBGColor)};
  padding-top: 64px;
  padding-bottom: 64px;
  text-align: center;
`
const MilestoneContainer = styled.div`
  display: flex !important;
  align-items: center !important;
  padding: 12px;
`
const MilestoneCardContainer = styled.div`
  background: #ffffff;
  padding: 24px 16px 40px 16px;
  border-radius: 24px;
  ${devices.mobile} {
    width: auto;
  }
  ${devices.tablet} {
    width: auto;
  }
  width: 390px;
  height: 100%;
`
const MilestoneCard = styled.div`
  display: flex;
  flex-direction: row;
  & svg {
    width: 50px;
    height: 50px;
    flex: none;
  }
`
const MilestoneTitle = styled.h5`
  text-align: left;
  & strong {
    color: #29398c;
    font-weight: bold;
  }
  ${devices.mobile} {
    font-size: var(--h5-font-size) !important;
  }
`
const MilestoneHeader = styled.div`
  padding-bottom: 4px;
  ${devices.mobile} {
    text-align: left;
  }
`
const MilestoneSubtitle = styled.p`
  padding-top: 8px;
  text-align: left;
`
const MilestonePoints = styled.p`
  color: #f96507;
  font-size: 2.4rem !important;
  flex-direction: row;
  display: flex;
  column-gap: 0.3rem;
  ${devices.mobile} {
    font-size: var(--h5-font-size) !important;
  }
`
const MilestonePointsTitle = styled.p`
  ${devices.mobile} {
    font-size: var(--p-font-size) !important;
  }
`
const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Milestone = styled.div`
  display: flex;
  margin: 0 15px;
  padding-top: 40px;

  ${devices.mobile} {
    display: grid;
    margin: 0px;
    padding: 0px;
  }
  ${devices.tablet} {
    display: grid;
    margin: 0px;
    padding: 0px;
    width: 430px !important;
  }
`
const Button = styled.div`
  border: none;
  padding-top: 28px;
  border-radius: 20px;
  text-align: center;
`
const MilstoneTitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
`
const MileStonegrid = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin-top: 8px;
`
const TopEarningActivity = ({
  field_component_title: topEarningComponentTitle,
  field_title_color: topEarningTitleColor,
  field_integration_type: topEarningIntegrationType,
  field_cta_1: topEarningCTAOne,
  field_cta_1_style: topEarningCTAOneStyle,
  field_bg_color: topEarningBGColor,
  loyaltyMilestones,
  getLoyaltyMilestones,
  donor,
  PostLoginPageData
}) => {
  const [openModal, setOpenModal] = useState(false)
  const { t, i18n } = useTranslation('TopEarningActivity')
  const [data, setData] = useState(null)
  const { isMobileWidth } = useScreenSize()
  const handleOpen = (type) => {
    setData(PostLoginPageData.find((item) => item.field_poup_type === type))
    setOpenModal(true)
  }
  useEffect(() => {
    if (donor) {
      getLoyaltyMilestones(donor?.defaultCenter?.sfid)
    }
  }, [donor, i18n.language])
  /* 
  checking for if donor default center is loyalty program enabled or not 
*/
  const isLoyaltyEnabled = donor?.defaultCenter?.isLoyaltyProgramEnabled || false
  return (
    /*
If the donor is a loyalty-enrolled member or not, and if the loyalty center is enabled or not
based on the field integration type, we are showing this component."
*/
    /*
we are gettign top earning activities in loyalty milestones
sorting milestones based on activity order field 
and showing only first 3 items
*/
    <>
      {(donor?.isLoyaltyMember === 'Yes' || donor?.isLoyaltyMember === 'No' || donor?.isLoyaltyMember === null) &&
        (isLoyaltyEnabled || !isLoyaltyEnabled) &&
        topEarningIntegrationType === 'top_earning_activities' && (
          <TopEarningActivityContainer $topEarningBGColor={topEarningBGColor}>
            <div className='main-container'>
              <MilestoneHeader $textColor={topEarningTitleColor} dangerouslySetInnerHTML={sanitizeHtml(topEarningComponentTitle?.value)} />
              <CarouselContainer>
                <Milestone>
                  {loyaltyMilestones &&
                    loyaltyMilestones.length > 0 &&
                    loyaltyMilestones
                      .filter((milestone) => milestone.bll_active__c)
                      .sort(
                        (activityOne, activityTwo) =>
                          activityOne.bll_top_earn_activity_order__c - activityTwo.bll_top_earn_activity_order__c
                      )
                      .slice(0, 3)
                      .map((milestone, index) => (
                        <MilestoneContainer>
                          <MilestoneCardContainer>
                            <MilestoneCard>
                              {index === 0 && <CheckMarkIconOne />}
                              {index === 1 && <CheckMarkIconTwo />}
                              {index === 2 && <CheckMarkIconThree />}
                              <MileStonegrid>
                                <MilstoneTitleDiv>
                                  <MilestoneTitle>
                                    <strong>{milestone?.bll_milestone_title__c}</strong>
                                  </MilestoneTitle>
                                  <MilestonePoints>
                                    <strong>{milestone?.bll_points__c}</strong>
                                    <MilestonePointsTitle>
                                      <strong>{t('pts')}</strong>
                                    </MilestonePointsTitle>
                                  </MilestonePoints>
                                </MilstoneTitleDiv>
                                <MilestoneSubtitle
                                  $textColor={topEarningTitleColor}
                                  dangerouslySetInnerHTML={sanitizeHtml(milestone?.bll_milestone_sub_title__c)}
                                />
                              </MileStonegrid>
                            </MilestoneCard>
                          </MilestoneCardContainer>
                        </MilestoneContainer>
                      ))}
                </Milestone>
              </CarouselContainer>
              {topEarningCTAOne ? (
                <Button>
                  <PrimaryButton
                    text={topEarningCTAOne?.title}
                    variant={topEarningCTAOneStyle}
                    type='submit'
                    onClick={() => handleOpen('ways_to_earn')}
                    fluid={isMobileWidth}
                  />
                </Button>
              ) : null}
              <CustomModal
                header={
                  <div
                    dangerouslySetInnerHTML={{ __html: data?.field_component_title?.processed }}
                    style={{ ...setTextColor(data?.field_title_color) }}
                  />
                }
                description={
                  <div
                    dangerouslySetInnerHTML={{ __html: data?.field_component_desc?.processed }}
                    style={{ ...setTextColor(data?.field_long_desc_color) }}
                  />
                }
                openModal={openModal}
                onClose={() => setOpenModal(false)}
              >
                {renderModalComponent(data?.field_poup_type)}
              </CustomModal>
            </div>
          </TopEarningActivityContainer>
        )}
    </>
  )
}
const mapStateToProps = (state, publicContent) => ({
  loyaltyMilestones: state.publicContent.getLoyaltyMilestonesSuccess,
  donor: state.donors.donors?.data,
  PostLoginPageData: state.publicContent?.getPostLoginPageSuccess?.contents
})

const mapDispatchToProps = (dispatch) => ({
  getLoyaltyMilestones: (sfid) => dispatch(publicContentActions.getLoyaltyMilestones(sfid))
})

export default connect(mapStateToProps, mapDispatchToProps)(TopEarningActivity)
