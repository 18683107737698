import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)

export const DashboardInfoContainer = styled.div`
  background-color: var(--color-smoke-grey);
  .LoaderContainer {
    padding-bottom: 64px;
  }
`

export const Title = styled.div`
  ${(props) => setTextColor(props.$sectionTitleColor)};
  padding-top: 64px;
  padding-bottom: 64px;
  ${devices.mobile} {
    padding-top: 40px;
    padding-bottom: 24px;
  }
`

export const CardsContainer = styled.div`
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 24px;
  padding-bottom: 64px;
  .link-style {
    height: 100%;
  }
  grid-template-columns: ${({ $hasInquiryData }) => ($hasInquiryData ? '234px 320px 406px' : '1fr 1fr')};
  ${devices.tablet} {
    grid-template-columns: ${({ $hasInquiryData }) => ($hasInquiryData ? 'repeat(3, 1fr)' : '1fr 1fr')};
    padding-bottom: 48px;
    grid-column-gap: 18px;
  }
  ${devices.mobile} {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 48px;
    grid-column-gap: 18px;
  }
`

export const Balance = styled.div`
  padding: 16px 40px 16px 40px;
  border-radius: 10px;
  background-color: var(--color-white);
  height: 100%;
  ${(props) => {
    if (!props.$hasInquiryData) {
      return `
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 32px;
      `
    }
  }}
  ${devices.tablet} {
    padding: 16px 24px;
    height: 100%;
  }
  ${devices.mobile} {
    padding: 8px 14px 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

export const BalanceTitle = styled.p`
  font-family: var(--font-family-bold) !important;
  font-size: var(--caption-mobile-font-size) !important;
  line-height: 2rem;
  color: var(--color-bl-blue);
  ${(props) => {
    if (props.$hasInquiryData) {
      return `
        margin-bottom: 4px;
      `
    } else {
      return `
        margin-bottom: 20px;
      `
    }
  }}
  ${devices.mobile} {
    font-family: var(--font-family-regular) !important;
  }
`

export const BalanceContent = styled.div`
  .card_balance {
    font-family: var(--font-family-bold) !important;
    font-size: var(--h4-font-size) !important;
    color: var(--color-orange);
    line-height: var(--h5-line-height);
    ${devices.tablet} {
      overflow-wrap: anywhere;
    }
    ${devices.mobile} {
      overflow-wrap: anywhere;
      font-size: var(--h5-mobile-font-size) !important;
    }
  }
  ${(props) => {
    if (props.$hasNoBalance) {
      return `
        .ui.button {
          text-align: left;
          font-size: var(--h5-font-size) !important;
          ${devices.mobile} {
            font-size: var(--caption-font-size) !important;
          }
        }
      `
    }
  }}
`

export const RewardPoints = styled.div`
  padding: 16px 71px 16px 71px;
  border-radius: 10px;
  background-color: var(--color-white);
  height: 100%;
  ${(props) => {
    if (!props.$hasInquiryData) {
      return `
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 32px;
      `
    }
  }}
  ${devices.tablet} {
    padding: 16px 24px;
    height: 100%;
  }
  ${devices.mobile} {
    padding: 8px 14px 8px 16px;
  }
`

export const RewardPointsTitle = styled.p`
  font-family: var(--font-family-bold) !important;
  font-size: var(--caption-mobile-font-size) !important;
  line-height: 2rem;
  color: var(--color-bl-blue);
  ${(props) => {
    if (props.$hasInquiryData) {
      return `
        margin-bottom: 4px;
      `
    } else {
      return `
        margin-bottom: 20px;
      `
    }
  }}
  ${devices.mobile} {
    font-family: var(--font-family-regular) !important;
  }
`

export const RewardPointsContent = styled.div`
  .loyalty_points {
    font-family: var(--font-family-bold) !important;
    font-size: var(--h4-font-size);
    color: var(--color-orange);
    line-height: var(--h5-line-height);
    ${devices.tablet} {
      overflow-wrap: anywhere;
    }
    ${devices.mobile} {
      overflow-wrap: anywhere;
      font-size: var(--h5-mobile-font-size) !important;
    }
  }
  ${(props) => {
    if (!props.$isLoyaltyMember) {
      return `
        .ui.button {
          text-align: left;
          font-size: var(--h5-font-size) !important;
          ${devices.mobile} {
            font-size: var(--caption-font-size) !important;
          }
        }
      `
    }
  }}
`

export const PhysicalDates = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background-color: var(--color-white);
  padding: 16px 31px 16px 31px;
  gap: 2.4rem;

  ${devices.tablet} {
    padding: 16px 24px;
    column-gap: 32px;
  }
  ${devices.mobile} {
    grid-column: 1/3;
  }
`

export const LastPhysicalDateWrapper = styled.div``

export const LastPhysicalDateTitle = styled.p`
  font-family: var(--font-family-bold) !important;
  font-size: var(--caption-mobile-font-size) !important;
  line-height: 2rem;
  color: var(--color-bl-blue);
  margin-bottom: 4px;
  ${devices.mobile} {
    font-family: var(--font-family-regular) !important;
  }
  ${(props) => {
    if (props.$language === 'es-MX') {
      return `
        min-height: 40px;
      `
    }
  }}
`

export const LastPhysicalDate = styled.div`
  font-family: var(--font-family-bold) !important;
  font-size: var(--h4-font-size);
  color: var(--color-orange);
  line-height: var(--h5-line-height);
  ${devices.mobile} {
    font-size: var(--h5-mobile-font-size) !important;
  }
`

export const NextPhysicalDateWrapper = styled.div``

export const NextPhysicalDateTitle = styled.p`
  font-family: var(--font-family-bold) !important;
  font-size: var(--caption-mobile-font-size) !important;
  color: var(--color-bl-blue);
  line-height: 2rem;
  margin-bottom: 4px;
  ${devices.mobile} {
    font-family: var(--font-family-regular) !important;
  }
  ${(props) => {
    if (props.$language === 'es-MX') {
      return `
        min-height: 40px;
      `
    }
  }}
`

export const NextPhysicalDate = styled.div`
  font-family: var(--font-family-bold) !important;
  font-size: var(--h4-font-size);
  color: var(--color-orange);
  line-height: var(--h5-line-height);
  ${devices.mobile} {
    font-size: var(--h5-mobile-font-size) !important;
  }
`
