import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import i18next from 'i18next'
import importer from 'common/importer'
import styled from 'styled-components'
import { devices } from '../utils/styledComponentHelpers'
const BlogView = importer('Components/BlogPage/BlogView')
const PublicContentActions = importer('Stores/PublicContent/Actions')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const PageNotFound = importer('Components/PageNotFound')
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
const { getClassFromPathname } = importer('Helpers/Utils', null)
const Breadcrumbs = importer('Components/Breadcrumbs')
const ArticleImage = importer('Components/ArticleImage')
const ArticleDetailsHeading = importer('Components/ArticleDetailsHeading')

const StyledDiv = styled.div`
  .text-component-description {
    padding-top: 1.6rem !important;
    padding-bottom: 4.8rem !important;
    ol {
      li {
        font-size: var(--p-font-size) !important;
        padding-bottom: 1.6rem;
        margin-left: 2rem;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
  ${devices.mobile} {
    .text-component-description:has(table) {
      overflow-x: auto;
      padding-bottom: 2.4rem !important;
      margin-bottom: 2.4rem !important;
      tr td {
        padding-right: 2rem;
      }
    }
    .gridImage {
      width: 100%;
      object-fit: contain !important;
    }
  }
`

const BlogDetailsContainer = () => {
  const [searchedBlog, setSearchedBlog] = useState({})
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const getBlogsPage = (slug = '') => dispatch(PublicContentActions.getBlogsPage(slug))
  const getBlogsPageLoading = useSelector((state) => state.publicContent?.getBlogsPageLoading)
  const getBlogsPageSuccess = useSelector((state) => state.publicContent?.getBlogsPageSuccess)
  const getBlogsDetailPage = () => {
    getBlogsPage()
  }
  useEffect(() => {
    getBlogsPage()
    window.scrollTo(0, 0)
  }, [pathname])
  useEffect(() => {
    if (getBlogsPageSuccess && getBlogsPageSuccess?.length) {
      const blogData = getBlogsPageSuccess?.filter((blog) => blog && blog.field_slug_value === pathname.slice(1))
      setSearchedBlog(...blogData)
    }
  }, [getBlogsPageSuccess])
  useEffect(() => {
    getBlogsDetailPage()
  }, [i18next.language])
  return (
    <>
      <Helmet>
        <title>{(searchedBlog && searchedBlog?.meta_title) || ''}</title>
        <meta name='description' content={`${(searchedBlog && searchedBlog?.meta_description) || ''}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      {getBlogsPageLoading ? (
        <LoaderSpinner />
      ) : searchedBlog && Object?.keys(searchedBlog)?.length ? (
        /*className is dynamically generated based on the pathname.
                @example
                if pathname is '/donation-process/faqs' then classname will be 'donation-process-faqs'*/
        <StyledDiv className={getClassFromPathname(pathname)}>
          <Breadcrumbs
            breadcrumbToggle={searchedBlog?.field_content_breadcrumbe}
            breadcrumbMobileToggle={searchedBlog?.field_mobile_breadcrumb}
            clickableLevelOne={true}
            title={searchedBlog?.title || ''}
          />
          <ArticleDetailsHeading testMeta={searchedBlog} />
          <ArticleImage testMetaImage={searchedBlog?.field_content_image} testMetaVideo={searchedBlog?.field_content_video} />

          {searchedBlog?.contents?.map((component, index) => {
            if (component.type === 'breadcrumb') {
              let tempComp = {
                ...component,
                breadcrumbToggle: searchedBlog?.field_content_breadcrumb,
                breadcrumbMobileToggle: searchedBlog?.field_mobile_breadcrumb,
                levelTwoPropsTitle: pathname.includes('faqs') ? 'FAQs' : null
              }
              component = tempComp
            } else if (component.type === 'ext_integration_component' && component.field_integration_type === 'related_article') {
              let tempComp = {
                ...component,
                category: searchedBlog && searchedBlog?.field_topic,
                blogsData: getBlogsPageSuccess.filter((item) => item.title !== searchedBlog.title),
                blog: searchedBlog,
                getBlogsDetailPage
              }
              component = tempComp
            }

            return renderDrupalComponent(component, index)
          })}
        </StyledDiv>
      ) : (
        <PageNotFound />
      )}
    </>
  )
}
export default BlogDetailsContainer
