export const contentConfigdata = {
  metadata: {
    landing_page: {
      machineNames: [
        {
          machineName: 'title',
          isReferenceType: false
        },
        {
          machineName: 'field_tab_title',
          isReferenceType: false
        },
        {
          machineName: 'field_content_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_mobile_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'path',
          isReferenceType: false
        },
        {
          machineName: 'moderation_state',
          isReferenceType: false
        },
        {
          machineName: 'field_components',
          isReferenceType: true
        }
      ]
    },
    testimonials: {
      machineNames: [
        {
          machineName: 'title',
          isReferenceType: false
        },
        {
          machineName: 'path',
          isReferenceType: false
        },
        {
          machineName: 'field_testimonial_components',
          isReferenceType: true
        },
        {
          machineName: 'field_ref_slug_value',
          isReferenceType: false
        }
      ]
    },
    header: {
      machineNames: [
        {
          machineName: 'title',
          isReferenceType: false
        },
        {
          machineName: 'path',
          isReferenceType: false
        },
        {
          machineName: 'field_biolifereward_logo',
          isMediaType: true
        },
        {
          machineName: 'field_header_components',
          isReferenceType: true
        },
        {
          machineName: 'field_global_chatbot',
          isReferenceType: false
        }
      ]
    },
    forms: {
      machineNames: [
        {
          machineName: 'title',
          isReferenceType: false
        },
        {
          machineName: 'field_content_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_mobile_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_slug_value',
          isReferenceType: false
        },
        {
          machineName: 'field_form_components',
          isReferenceType: true
        },
        {
          machineName: 'field_chatbot',
          isReferenceType: false
        }
      ]
    },
    page: {
      machineNames: [
        {
          machineName: 'field_meta_tags',
          isReferenceType: false
        },
        {
          machineName: 'field_content_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_mobile_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_slug_value',
          isReferenceType: false
        },
        {
          machineName: 'field_page_components',
          isReferenceType: true
        },
        {
          machineName: 'field_chatbot',
          isReferenceType: false
        }
      ]
    },
    signup_pages: {
      machineNames: [
        {
          machineName: 'title',
          isReferenceType: false
        },
        {
          machineName: 'field_content_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_mobile_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_slug_value',
          isReferenceType: false
        },
        {
          machineName: 'field_signup_components',
          isReferenceType: true
        },
        {
          machineName: 'field_chatbot',
          isReferenceType: false
        }
      ]
    },
    location_pages: {
      machineNames: [
        {
          machineName: 'title',
          isReferenceType: false
        },
        {
          machineName: 'field_content_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_mobile_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_slug_value',
          isReferenceType: false
        },
        {
          machineName: 'field_locationpage_components',
          isReferenceType: true
        },
        {
          machineName: 'field_chatbot',
          isReferenceType: false
        }
      ]
    },
    center_pages: {
      machineNames: [
        {
          machineName: 'title',
          isReferenceType: false
        },
        {
          machineName: 'field_content_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_mobile_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_slug_value',
          isReferenceType: false
        },
        {
          machineName: 'field_center_components',
          isReferenceType: true
        },
        {
          machineName: 'field_chatbot',
          isReferenceType: false
        }
      ]
    },
    post_login_pages: {
      machineNames: [
        {
          machineName: 'title',
          isReferenceType: false
        },
        {
          machineName: 'field_content_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_mobile_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_slug_value',
          isReferenceType: false
        },
        {
          machineName: 'field_postlogin_components',
          isReferenceType: true
        },
        {
          machineName: 'field_chatbot',
          isReferenceType: false
        }
      ]
    },
    article: {
      machineNames: [
        {
          machineName: 'title',
          isReferenceType: false
        },
        {
          machineName: 'field_content_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_mobile_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_read_time',
          isReferenceType: false
        },
        {
          machineName: 'body',
          isReferenceType: false
        },
        {
          machineName: 'field_topic',
          isReferenceType: false
        },
        {
          machineName: 'field_is_featured',
          isReferenceType: false
        },
        {
          machineName: 'field_slug_value',
          isReferenceType: false
        },
        { machineName: 'field_article_components', isReferenceType: true },
        {
          machineName: 'field_content_image',
          isMediaType: true
        },
        {
          machineName: 'field_content_video',
          isMediaType: true
        },
        {
          machineName: 'changed',
          isReferenceType: false
        },
        {
          machineName: 'created',
          isReferenceType: false
        },
        {
          machineName: 'updated'
        },
        {
          machineName: 'field_chatbot',
          isReferenceType: false
        }
      ]
    },
    news: {
      machineNames: [
        {
          machineName: 'title',
          isReferenceType: false
        },
        {
          machineName: 'field_content_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_mobile_breadcrumb',
          isReferenceType: false
        },
        {
          machineName: 'field_read_time',
          isReferenceType: false
        },
        {
          machineName: 'body',
          isReferenceType: false
        },
        {
          machineName: 'field_content_image',
          isMediaType: true
        },
        {
          machineName: 'field_content_video',
          isMediaType: true
        },
        {
          machineName: 'field_category',
          isReferenceType: false
        },
        {
          machineName: 'field_slug_value',
          isReferenceType: false
        },
        {
          machineName: 'field_news_components',
          isReferenceType: true
        },
        {
          machineName: 'changed',
          isReferenceType: false
        },
        {
          machineName: 'created',
          isReferenceType: false
        },
        {
          machineName: 'field_chatbot',
          isReferenceType: false
        }
      ]
    },
    promotional_content: {
      machineNames: [
        {
          machineName: 'field_meta_tags',
          isReferenceType: false
        },
        {
          machineName: 'field_slug_value',
          isReferenceType: false
        },
        {
          machineName: 'field_promotional_components',
          isReferenceType: true
        }
      ]
    }
  },
  paragraphs: {
    header_component: {
      machineNames: [
        {
          machineName: 'field_menu_type',
          isMediaType: false
        },
        {
          machineName: 'field_logo',
          isMediaType: true
        },
        {
          machineName: 'field_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_cta_2',
          isMediaType: false
        },
        {
          machineName: 'field_cta_2_style',
          isMediaType: false
        }
      ]
    },
    hero: {
      machineNames: [
        {
          machineName: 'field_hero_template_type',
          isMediaType: false
        },
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_long_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_bg_image',
          isMediaType: true
        },
        {
          machineName: 'field_component_mbg_image',
          isMediaType: true
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_asset',
          isMediaType: true
        },
        {
          machineName: 'field_column_1_icon',
          isMediaType: true
        },
        {
          machineName: 'field_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_cta_2',
          isMediaType: false
        },
        {
          machineName: 'field_cta_2_style',
          isMediaType: false
        },
        {
          machineName: 'field_asset_link',
          isMediaType: false
        }
      ]
    },
    column_with_text_component: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_1_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_2_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_2_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_2_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_3_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_3_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_3_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_4_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_4_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_4_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_column_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_long_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },

        {
          machineName: 'field_grid_template_type',
          isMediaType: false
        },
        {
          machineName: 'field_text_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_grid_flag',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color_1',
          isMediaType: false
        }
      ]
    },
    search_component: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_asset',
          isMediaType: true
        },
        {
          machineName: 'field_component_mb_asset',
          isMediaType: true
        },
        {
          machineName: 'field_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_help_text',
          isMediaType: false
        },
        {
          machineName: 'field_component_bg_image',
          isMediaType: true
        },
        {
          machineName: 'field_component_mbg_image',
          isMediaType: true
        },
        {
          machineName: 'field_column_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_container_size',
          isMediaType: false
        }
      ]
    },
    statistic_component: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_asset',
          isMediaType: true
        },
        {
          machineName: 'field_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_component_title_1',
          isMediaType: false
        },
        {
          machineName: 'field_text_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_title_2',
          isMediaType: false
        },
        {
          machineName: 'field_column_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color_1',
          isMediaType: false
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_long_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_ref_ctype',
          isMediaType: false
        },
        {
          machineName: 'field_ref_slug_value',
          isMediaType: false
        }
      ]
    },
    hero_with_list_component: {
      machineNames: [
        {
          machineName: 'field_list_template_type',
          isMediaType: false
        },
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_asset',
          isMediaType: true
        },
        {
          machineName: 'field_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_asset_link',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_1_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_url',
          isMediaType: false
        },
        {
          machineName: 'field_column_2_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_2_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_2_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_2_url',
          isMediaType: false
        },
        {
          machineName: 'field_column_3_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_3_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_3_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_3_url',
          isMediaType: false
        },
        {
          machineName: 'field_column_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_column_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_long_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        }
      ]
    },
    text_component: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_long_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_cta_2',
          isMediaType: false
        },
        {
          machineName: 'field_cta_2_style',
          isMediaType: false
        },
        {
          machineName: 'field_text_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_width',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        }
      ]
    },
    row: {
      machineNames: [
        {
          machineName: 'field_bg_color_1',
          isMediaType: false
        },
        {
          machineName: 'field_web_margin',
          isMediaType: false
        },
        {
          machineName: 'field_tab_margin',
          isMediaType: false
        },
        {
          machineName: 'field_mobile_margin',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color_1',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        }
      ]
    },
    registration_form_component: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_asset',
          isMediaType: true
        },
        {
          machineName: 'field_component_mb_asset',
          isMediaType: true
        },
        {
          machineName: 'field_form_label',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        }
      ]
    },
    footer_component: {
      machineNames: [
        {
          machineName: 'field_logo',
          isMediaType: true
        },
        {
          machineName: 'field_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_1_url',
          isMediaType: false
        },
        {
          machineName: 'field_column_2_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_2_url',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_footer_menu_type',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_sm_icon_1',
          isMediaType: true
        },
        {
          machineName: 'field_sm_icon_1_url',
          isMediaType: false
        },
        {
          machineName: 'field_sm_icon_2',
          isMediaType: true
        },
        {
          machineName: 'field_sm_icon_2_url',
          isMediaType: false
        },
        {
          machineName: 'field_sm_icon_3',
          isMediaType: true
        },
        {
          machineName: 'field_sm_icon_3_url',
          isMediaType: false
        },
        {
          machineName: 'field_sm_icon_4',
          isMediaType: true
        },
        {
          machineName: 'field_sm_icon_4_url',
          isMediaType: false
        },
        {
          machineName: 'field_sm_icon_5',
          isMediaType: true
        },
        {
          machineName: 'field_sm_icon_5_url',
          isMediaType: false
        },
        {
          machineName: 'field_sm_icon_6',
          isMediaType: true
        },
        {
          machineName: 'field_sm_icon_6_url',
          isMediaType: false
        },
        {
          machineName: 'field_sm_icon_7',
          isMediaType: true
        },
        {
          machineName: 'field_sm_icon_7_url',
          isMediaType: false
        },
        {
          machineName: 'field_takeda_logo',
          isMediaType: true
        },
        {
          machineName: 'field_takeda_logo_url',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        }
      ]
    },
    testimonials: {
      machineNames: [
        {
          machineName: 'field_component_title_1',
          isMediaType: false
        },
        {
          machineName: 'field_component_title_2',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_image',
          isMediaType: true
        },
        {
          machineName: 'field_custom_id',
          isReferenceType: false
        },
        {
          machineName: 'field_cta_1',
          isReferenceType: false
        },
        {
          machineName: 'field_cta_1_style',
          isReferenceType: false
        }
      ]
    },
    '404_component': {
      machineNames: [
        {
          machineName: 'field_component_asset',
          isMediaType: true
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_long_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_cta_2',
          isMediaType: false
        },
        {
          machineName: 'field_cta_2_style',
          isMediaType: false
        }
      ]
    },
    text_banner: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        }
      ]
    },
    breadcrumb: {
      machineNames: [
        {
          machineName: 'field_column_1_url',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        }
      ]
    },
    form_field_component: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_component_title_1',
          isMediaType: false
        },
        {
          machineName: 'field_input_type',
          isMediaType: false
        },
        {
          machineName: 'field_status',
          isMediaType: false
        },
        {
          machineName: 'field_component_title_2',
          isMediaType: false
        }
      ]
    },
    form_cta_component: {
      machineNames: [
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        }
      ]
    },
    text_cta_grid: {
      machineNames: [
        {
          machineName: 'field_txtgrid_template_type',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_column_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_desc',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_icon',
          isMediaType: true
        },
        {
          machineName: 'field_grid_column',
          isMediaType: false
        },
        {
          machineName: 'field_txtgrid_template_type',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        }
      ]
    },
    form_confirmation: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        }
      ]
    },
    separator_component: {
      machineNames: [
        {
          machineName: 'field_separator',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        }
      ]
    },
    ext_integration_component: {
      machineNames: [
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_integration_type',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_cta_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        }
      ]
    },
    cta_component: {
      machineNames: [
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_cta_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        }
      ]
    },
    progress_bar_component: {
      machineNames: [
        {
          machineName: 'field_pb_steps',
          isMediaType: false
        }
      ]
    },
    accordion_component: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_long_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_accordian_flag',
          isMediaType: false
        },
        {
          machineName: 'field_accordion_type',
          isMediaType: false
        },
        {
          machineName: 'field_component_image',
          isMediaType: true
        },
        {
          machineName: 'field_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        }
      ]
    },
    text_media: {
      machineNames: [
        {
          machineName: 'field_component_image',
          isMediaType: true
        },
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_long_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_alignment_2',
          isMediaType: false
        },
        {
          machineName: 'field_component_asset',
          isMediaType: true
        },
        {
          machineName: 'field_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_asset_link',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_asset',
          isMediaType: true
        },
        {
          machineName: 'field_text_media_type',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_icon',
          isMediaType: true
        },
        {
          machineName: 'field_component_title_1',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color_1',
          isMediaType: false
        }
      ]
    },
    checklist_component: {
      machineNames: [
        {
          machineName: 'field_column_1_icon',
          isMediaType: true
        },
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_long_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color_1',
          isMediaType: false
        }
      ]
    },
    text_icon_grid: {
      machineNames: [
        {
          machineName: 'field_column_1_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_column_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_1_url',
          isMediaType: false
        },
        {
          machineName: 'field_column_2_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_2_url',
          isMediaType: false
        },
        {
          machineName: 'field_grid_column',
          isMediaType: false
        }
      ]
    },
    tab_component: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        }
      ]
    },
    integration_content_component: {
      machineNames: [
        { machineName: 'field_component_title', isMediaType: false },
        { machineName: 'field_title_color', isMediaType: false },
        { machineName: 'field_component_desc', isMediaType: false },
        { machineName: 'field_long_desc_color', isMediaType: false }
      ]
    },
    image_banner_component: {
      machineNames: [
        {
          machineName: 'field_img_banner_template',
          isMediaType: false
        },
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_bg_image',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_bg_image',
          isMediaType: true
        },
        {
          machineName: 'field_component_mbg_image',
          isMediaType: true
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_alignment_2',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        }
      ]
    },
    accordian_grid_component: {
      machineNames: [
        {
          machineName: 'field_accordian_flag',
          isMediaType: false
        },
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_long_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_alignment_2',
          isMediaType: true
        },
        {
          machineName: 'field_component_asset',
          isMediaType: true
        },
        {
          machineName: 'field_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_asset_link',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_asset',
          isMediaType: true
        },
        {
          machineName: 'field_text_media_type',
          isMediaType: false
        },
        {
          machineName: 'field_component_image',
          isMediaType: true
        },
        {
          machineName: 'field_column_1_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_2_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_2_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_2_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_3_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_3_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_3_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_4_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_4_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_4_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_5_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_5_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_5_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_6_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_6_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_6_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_grid_column',
          isMediaType: false
        }
      ]
    },
    accodion_with_multi_components: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        },
        {
          machineName: 'field_is_parent',
          isMediaType: false
        },
        {
          machineName: 'field_accordian_flag',
          isMediaType: false
        }
      ]
    },
    slider_banner_component: {
      machineNames: [
        {
          machineName: 'field_component_image',
          isMediaType: true
        },
        {
          machineName: 'field_component_mobile_image',
          isMediaType: true
        }
      ]
    },
    announcement_component: {
      machineNames: [
        {
          machineName: 'field_component_title_1',
          isMediaType: false
        },
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        }
      ]
    },
    bonus_grid_component: {
      machineNames: [
        {
          machineName: 'field_component_title_1',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_url',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_desc',
          isMediaType: false
        },
        {
          machineName: 'field_component_title_2',
          isMediaType: false
        },
        {
          machineName: 'field_column_2_url',
          isMediaType: false
        },
        {
          machineName: 'field_column_2_title',
          isMediaType: false
        },
        {
          machineName: 'field_column_2_desc',
          isMediaType: false
        },
        {
          machineName: 'field_column_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_column_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_cta_2',
          isMediaType: false
        },
        {
          machineName: 'field_cta_2_style',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        }
      ]
    },
    center_page_top_component: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_title_1',
          isMediaType: false
        },
        {
          machineName: 'field_component_title_2',
          isMediaType: false
        },
        {
          machineName: 'field_component_image',
          isMediaType: true
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_long_desc_color',
          isMediaType: false
        }
      ]
    },
    advertisement_card_component: {
      machineNames: [
        {
          machineName: 'field_adv_template_type',
          isMediaType: false
        },
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_image',
          isMediaType: true
        },
        {
          machineName: 'field_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color_1',
          isMediaType: false
        }
      ]
    },
    photo_gallery_component: {
      machineNames: [
        {
          machineName: 'field_component_image',
          isMediaType: true
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        }
      ]
    },
    meet_our_staff_component: {
      machineNames: [
        {
          machineName: 'field_component_title_1',
          isMediaType: false
        },
        {
          machineName: 'field_component_title_2',
          isMediaType: false
        },
        {
          machineName: 'field_component_image',
          isMediaType: true
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        }
      ]
    },
    accordion_with_slider_component: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        },
        {
          machineName: 'field_is_parent',
          isMediaType: false
        },
        {
          machineName: 'field_accordian_flag',
          isMediaType: false
        }
      ]
    },
    reference_content_component: {
      machineNames: [
        {
          machineName: 'field_ref_ctype',
          isMediaType: false
        },
        {
          machineName: 'field_ref_slug_value',
          isMediaType: false
        },
        {
          machineName: 'field_testimonial_template_type',
          isMediaType: false
        }
      ]
    },
    card_component: {
      machineNames: [
        {
          machineName: 'field_card_template_type',
          isMediaType: false
        },
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_long_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_image',
          isMediaType: true
        },
        {
          machineName: 'field_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color_1',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_bg_image',
          isMediaType: true
        },
        {
          machineName: 'field_column_1_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_1_url',
          isMediaType: false
        },
        {
          machineName: 'field_column_2_icon',
          isMediaType: true
        },
        {
          machineName: 'field_column_2_url',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        }
      ]
    },
    image_component: {
      machineNames: [
        {
          machineName: 'field_component_image',
          isMediaType: true
        },
        {
          machineName: 'field_image_type',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        },
        {
          machineName: 'field_text_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_asset_link',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        }
      ]
    },
    tab_with_multi_components: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_is_parent',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        },
        {
          machineName: 'field_accordian_flag',
          isMediaType: false
        }
      ]
    },
    news_blog_banner_component: {
      machineNames: [
        {
          machineName: 'field_component_image',
          isMediaType: true
        },
        {
          machineName: 'field_component_video',
          isMediaType: false
        }
      ]
    },
    biolife_rewards_component: {
      machineNames: [
        {
          machineName: 'field_donor_type',
          isMediaType: false
        },
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_short_description',
          isMediaType: false
        },
        {
          machineName: 'field_short_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_image',
          isMediaType: true
        },
        {
          machineName: 'field_alignment',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_icon',
          isMediaType: true
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_custom_id',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color_1',
          isMediaType: false
        }
      ]
    },
    pop_up_component: {
      machineNames: [
        {
          machineName: 'field_poup_type',
          isMediaType: false
        },
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_long_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_icon',
          isMediaType: true
        },
        {
          machineName: 'field_checkbox_content',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        }
      ]
    },
    quote_component: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_component_color',
          isMediaType: false
        }
      ]
    },
    donor_text_component: {
      machineNames: [
        {
          machineName: 'field_donor_type',
          isMediaType: false
        },
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_title_color',
          isMediaType: false
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_long_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1_style',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_text_alignment',
          isMediaType: false
        }
      ]
    },
    notification_banner_component: {
      machineNames: [
        {
          machineName: 'field_notification_type',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_text_color',
          isMediaType: false
        },
        {
          machineName: 'field_column_1_icon',
          isMediaType: true
        }
      ]
    },
    about_author_component: {
      machineNames: [
        {
          machineName: 'field_component_title',
          isMediaType: false
        },
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_component_image',
          isMediaType: true
        }
      ]
    },
    promotional_content_header: {
      machineNames: [
        {
          machineName: 'field_component_desc',
          isMediaType: false
        },
        {
          machineName: 'field_bg_color',
          isMediaType: false
        },
        {
          machineName: 'field_cta_1',
          isMediaType: false
        },
        {
          machineName: 'field_long_desc_color',
          isMediaType: false
        },
        {
          machineName: 'field_promo_display',
          isMediaType: false
        }
      ]
    }
  }
}
