import React from 'react'
import { EndChatContainer, EndChatDiv, H4Text, Desc, ButtonsDiv } from './endChatpopup.styled'
import PrimaryButton from '../../../Buttons/PrimaryButton'

function EndChatpopup({ closeFunction, closeChatbotAndReset }) {
  return (
    <EndChatContainer>
      <EndChatDiv>
        <H4Text>End Chat</H4Text>
        <Desc>Are you sure you want to end chat? This action cannot be undone.</Desc>
      </EndChatDiv>
      <ButtonsDiv>
        <PrimaryButton text={'Continue'} fluid onClick={() => closeChatbotAndReset()} />
        <PrimaryButton variant='blue_underline' text={'Cancel'} fluid onClick={closeFunction} />
      </ButtonsDiv>
    </EndChatContainer>
  )
}

export default EndChatpopup
