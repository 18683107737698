import React from 'react'
import { Card, Segment, Image } from 'semantic-ui-react'
import { ReactSVG } from 'react-svg'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
import { useNavigate } from 'react-router-dom'
import LocationIcon from '../../../assets/media/icons/icon-location-new.svg'
import PhoneIcon from '../../../assets/media/icons/icon-phone-in-talk.svg'
import HomeCenterIcon from '../../../assets/media/icons/icon-star-home-center.svg'
import { formatPhoneNumber } from '../../Helpers/Utils'
import styled from 'styled-components'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
import { ROUTES } from '../../NavigationService'

const CenterGetDirectionButton = styled(PrimaryButton)`
  color: var(--color-white) !important;
  font-size: var(--caption-font-size) !important;
`

const AvailableAppointmentCard = ({ centerData, isHomeCenter }) => {
  const { t } = useTranslation('MyAppointments')
  const openMap = (centerData) => {
    return window.open(`https://www.google.com/maps/place/${centerData.latitude},${centerData.longitude}`)
  }
  const navigate = useNavigate()
  return (
    <Card raised className={'AvailableAppointmentCardContainer'}>
      <Card.Content>
        <Card.Header>
          <div className='HeaderWrapper'>
            {centerData ? (
              <CenterGetDirectionButton text={t('getDirections')} variant={'blue_underline'} onClick={() => openMap(centerData)} />
            ) : null}
            {!!centerData?.distanceFromHomeCenter && (
              <div className='DistanceBox'>
                <ReactSVG className='LocationIconSmall' src={LocationIcon} />
                <span className='CenterDistance'>{`${(centerData.distanceFromHomeCenter / 1609.34).toFixed(2)} ${t('mi')}`}</span>
              </div>
            )}
          </div>
        </Card.Header>
        <Card.Description onClick={() => navigate(ROUTES.AppointmentScheduling, { state: { selectedCenterData: centerData } })}>
          {!!centerData.webImageUrl && <Image height='140' width='100%' alt={centerData.name} src={centerData.webImageUrl} />}
          <Segment basic>
            <div>
              <div className='CenterBox'>
                <p className='CenterName'>
                  {centerData.name}
                  {isHomeCenter}
                </p>
                {isHomeCenter && <ReactSVG className='HomeCenterIcon' src={HomeCenterIcon} />}
              </div>
              <div className='AddressBox'>
                <ReactSVG className='LocationIcon' src={LocationIcon} />
                <p className='CenterAddress'>
                  {centerData.addressLine1}
                  <br />
                  {`${centerData.city}, ${centerData.stateCode} ${centerData.zipcode}`}
                  <br />
                </p>
              </div>
              <div className='PhoneNumberBox'>
                <ReactSVG className='PhoneIcon' src={PhoneIcon} />
                <p className='PhoneNumber' href={`tel:${centerData.telephone}`}>
                  {formatPhoneNumber(centerData.telephone)}
                </p>
              </div>
              {centerData.isSpanishSupported ? <p className='IsSpanishCenter'>{t('habloEspanol')}</p> : null}
            </div>
          </Segment>
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default AvailableAppointmentCard
