import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Form as FinalForm, Field } from 'react-final-form'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import SearchInputButton from '../../Inputs/SearchInputButton'
import PrimaryButton from '../../Buttons/PrimaryButton'
import CentersActions from '@takedapdt/biolife-core/src/Stores/Centers/Actions'
import CenterCard from '../../CenterCard'
import Loader from '../../Loaders/LoaderSpinner'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'
import { getCssColorFromDrupalColorType, setTextColor } from '../../../Constant/Utils'
import { useNavigate } from 'react-router-dom'
import { devices } from '../../../utils/styledComponentHelpers'
import { mapOptionsByState } from '../../../Helpers/Utils'
import { ROUTES } from '../../../NavigationService'

const StyledCardLocationsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 8.5rem;
  margin-bottom: 4rem;
  ${devices.mobile} {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    gap: 3.2rem;
  }
  .CenterCardContainer {
    &.card {
      ${devices.mobile} {
        max-width: 32rem;
        width: 100% !important;
      }
      &:first-child,
      &:last-child {
        margin: 1.6rem 0;
      }
      min-height: unset !important;
      .content .header + .description {
        min-height: 23rem !important;
        ${devices.mobile} {
          min-height: 18rem !important;
          .IsSpanishCenter {
            margin-bottom: 1.8rem;
          }
        }
      }
    }
    .CheckMarkContainer {
      .CheckMark {
        top: 0.3rem;
      }
    }
    .description {
      /* svg path {
        fill: var(--color-orange) !important;
      } */
    }
    .CardSeparator {
      display: none;
    }
    .CardInfoText {
      display: none;
    }
  }
`

const StyledSearchLocationContainer = styled.div`
  ${devices.mobile} {
    background-color: var(--color-white);
    z-index: 10;
  }
  .SearchInputButton {
    width: 100%;
    .ui.input > input {
      border-color: var(--color-dark-grey);
      &:focus {
        border-color: var(--color-bl-blue);
      }
    }
    .ui.input,
    .TextButtonContainer {
      width: 100%;
    }
    button {
      display: none !important;
    }
  }
  .searchInnerDiv {
    padding: 0 8.5rem;
    ${devices.mobile} {
      padding: 0 2.5rem;
    }
  }
`

const StyledButtonsContainer = styled.div`
  display: flex;
  padding: 4rem 0 6.4rem;
  justify-content: center;
  position: fixed;
  z-index: 6;
  background: var(--color-white);
  margin: 0 !important;
  border-bottom-left-radius: 2.4rem;
  border-bottom-right-radius: 2.4rem;
  box-shadow: 0rem -0.4rem 0.8rem var(--color-box-shadow-grey);
  position: absolute;
  bottom: 0;
  width: 100%;
  ${devices.mobile} {
    padding: 2.4rem 1.5rem 4.8rem;
  }
`

const StyledShowMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15.5rem;
  button {
    margin-bottom: 5rem !important;
  }
  ${devices.mobile} {
    margin-bottom: 9.5rem;
    padding: 0 1.5rem;
  }
`
const NoteContainer = styled.div`
  padding: 1.8rem 8.5rem;
  ${devices.mobile} {
    padding: 1.8rem 2.5rem;
  }
`

const Note = styled.p`
  font-size: var(--caption-font-size);
  text-align: left;
`

const SearchLocation = (props) => {
  const {
    options,
    centers,
    favCenterList,
    centerOpsSearch,
    centerID,
    handleOnChange,
    allowZipcodeSearch,
    searchedCenters,
    searchCentersLoading,
    getZipcodeSearchCenters,
    fieldLabel,
    fieldColor,
    setIsChangeLocationModal,
    donorHomeCenter,
    getAllCenters,
    favourites,
    center,
    allCenters
  } = props
  //contains all centers state wise
  const [centerList, setCenterList] = useState(options)
  const [selectedValue, setSelectedValue] = useState({})
  const [updated, setUpdated] = useState(false)
  const [userInput, setUserInput] = useState('')
  const [searchError, setSearchError] = useState(false)
  //flag to check if a center is clicked
  const [centerChecked, setCenterChecked] = useState(false)
  //contains the clicked centers data
  const [selectedCenter, setSelectedCenter] = useState(null)
  //initially shows first 5 centers if any and gets updated on show-more button click
  const [visibleCenters, setVisibleCenters] = useState()
  const [showMore, setShowMore] = useState()
  // const homeCenter = allCenters?.filter((center) => center?.sfid === donorHomeCenter)
  const { isMobileWidth } = useScreenSize()
  const { t } = useTranslation('CenterSelection')
  const navigate = useNavigate()
  const preparedOptions = async (searchedCenters) => {
    const res = await mapOptionsByState(searchedCenters)
    return res
  }

  /**
   * @description Handles search submit
   * @param {Object} searchedValue - the searched value in input tag
   * @returns -- calls any of the functions depending upon the serach value || centerOpsSearch flag
   */
  const onSubmit = (searchedValue) => {
    let value = searchedValue?.search?.trim()
    setUserInput(value)
    if (allowZipcodeSearch && !isNaN(parseInt(value, 10)) && value?.length === 5) {
      getZipcodeSearchCenters(value, true, 'disDate')
    } else if (centerOpsSearch) {
      handleCenterOpsFilter(value)
    } else {
      handleFilter(value)
    }
  }

  /**
   * @description Handles filtering centers and arranging them state wise
   * @param {Object} searchedValue - the searched value in input tag
   * @returns -- sets centerList to the filtered centers data arranged according to the state list
   */
  const handleFilter = async (value) => {
    let finalFilteredCenters = []
    if (value?.length > 1) {
      const filteredStates = options.filter(({ state, stateCode }) => {
        return state.substring(0, value.length).toLowerCase() === value.toLowerCase() || stateCode?.toLowerCase() === value.toLowerCase()
      })
      finalFilteredCenters.push(...filteredStates)
      const filteredCenters = centers?.filter((item) => item?.name.substring(0, value.length).toLowerCase() === value.toLowerCase())
      await mapOptionsByState(filteredCenters).then((res) => finalFilteredCenters.push(...res))
      setCenterList(finalFilteredCenters)
    } else {
      setCenterList(options)
    }
  }

  const handleCenterOpsFilter = async (value) => {
    let finalFilteredCenters = []
    if (value?.length > 1) {
      const filteredStates = options.filter(({ state, stateCode }) => {
        return state.substring(0, value.length).toLowerCase() === value.toLowerCase() || stateCode?.toLowerCase() === value.toLowerCase()
      })
      finalFilteredCenters.push(...filteredStates)
      const filteredCenters = centers?.filter(
        ({ details }) => details?.name.substring(0, value.length).toLowerCase() === value.toLowerCase()
      )
      await mapOptionsByState(filteredCenters, true).then((res) => finalFilteredCenters.push(...res))
      setCenterList(finalFilteredCenters)
    } else {
      setCenterList(options)
    }
  }

  const findSelectedObj = (arr, value) => arr?.find((op) => op?.sfid === value)

  const handleSelection = (option) => {
    setSelectedValue(option)
    setUpdated(true)
    handleOnChange(option)
    getAllCenters(option?.key)
  }

  useEffect(() => {
    setCenterList(options)
    if (centerID) {
      const defaultOption = findSelectedObj(centers, centerID)
      if (defaultOption) {
        setSelectedValue({
          key: defaultOption?.sfid,
          zipCode: defaultOption?.zipcode,
          value: defaultOption?.sfid,
          centerName: defaultOption?.centerName,
          name: defaultOption?.name,
          text: `${defaultOption?.name} Center`,
          stateCode: defaultOption?.stateCode,
          city: defaultOption?.city,
          address: defaultOption?.addressLine1,
          telephone: defaultOption?.telephone
        })
      }
    }
  }, [options])

  useEffect(() => {
    if (centerID) {
      const defaultOption = findSelectedObj(centers, centerID)
      if (defaultOption) {
        setSelectedValue({
          key: defaultOption?.sfid,
          zipCode: defaultOption?.zipcode,
          value: defaultOption?.sfid,
          centerName: defaultOption?.centerName,
          name: defaultOption?.name,
          text: `${defaultOption?.name} Center`,
          stateCode: defaultOption?.stateCode,
          city: defaultOption?.city,
          address: defaultOption?.addressLine1,
          telephone: defaultOption?.telephone
        })
      } else {
        setSelectedValue({
          key: centers?.[0]?.sfid,
          zipCode: centers?.[0]?.zipcode,
          value: centers?.[0]?.sfid,
          centerName: centers?.[0]?.centerName,
          name: centers?.[0]?.name,
          text: `${centers?.[0]?.name} Center`,
          stateCode: centers?.[0]?.stateCode,
          city: centers?.[0]?.city,
          address: centers?.[0]?.addressLine1,
          telephone: centers?.[0]?.telephone
        })
      }
    }
  }, [])

  /**
   * @description Handles click event on the center card component
   * @param {Object} centerData - The center data information.
   * @returns -- sets selectedCenter with the centerData and the centerChecked flag
   */
  const handleCenterCardClick = (centerData) => {
    if (selectedCenter?.key === centerData.key) {
      setCenterChecked(false)
      setSelectedCenter()
    } else {
      setCenterChecked(true)
      setSelectedCenter(centerData)
    }
  }
  const handleRedirect = () => {
    navigate(ROUTES.Settings, { state: { preferences: true } })
  }
  /**
   * @description Shows more centers by updating the visible centers state
   * @returns -- sets visible centers with the next 5 available centers
   */
  const showMoreCenters = () => {
    let centers = centerList.map((item) => item?.facilities).flat()
    const nextCenters = centers?.slice(visibleCenters.length, visibleCenters.length + 5)
    setVisibleCenters([...visibleCenters, ...nextCenters])
    if (visibleCenters.length + nextCenters.length >= centers?.length) {
      setShowMore(false)
    }
  }
  /**
   * @description initially sets visible center to first 5 centers from the centerList
   */

  useEffect(() => {
    let centers = centerList?.map((item) => item?.facilities).flat()
    setVisibleCenters(centers?.slice(0, 5))
    setShowMore(centers?.length > 5)
  }, [centerList])

  return (
    <StyledSearchLocationContainer>
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {!favourites ? (
              allowZipcodeSearch ? (
                <div className='searchInnerDiv'>
                  <Field
                    name='search'
                    render={({ input, meta }) => (
                      <SearchInputButton
                        type='text'
                        fieldLabel={<div dangerouslySetInnerHTML={{ __html: fieldLabel }} style={{ ...setTextColor(fieldColor) }} />}
                        {...input}
                        defaultValue={''}
                        height={56}
                        error={searchError}
                        errorMessage={'errorMessage'}
                        onBlur={() => {
                          input.onBlur(), setSearchError(false)
                        }}
                        onChange={(e, val) => {
                          val && searchError
                            ? setSearchError(false)
                            : (null, input.onChange(val.value), setCenterChecked(false), setSelectedCenter())
                        }}
                      />
                    )}
                  />
                </div>
              ) : (
                <NoteContainer>
                  <Note>{t('notAllCentersPhysical')}</Note>
                </NoteContainer>
              )
            ) : visibleCenters?.length > 0 ? (
              !allowZipcodeSearch ? (
                <NoteContainer>
                  <Note>{t('physicalFav')}</Note>
                </NoteContainer>
              ) : null
            ) : favCenterList?.length > 0 ? (
              <NoteContainer>
                <Note>{t('noNearbyFav')}</Note>
              </NoteContainer>
            ) : (
              <NoteContainer>
                <Note>{t('noFav')}</Note>
              </NoteContainer>
            )}
            <StyledCardLocationsDiv>
              {searchCentersLoading ? (
                <Loader />
              ) : (
                visibleCenters?.map((option) => (
                  <CenterCard
                    key={option.value}
                    onCenterCardClick={() => {
                      setSelectedCenter(option)
                      handleCenterCardClick(option)
                    }}
                    favCenterList={favCenterList}
                    isHomeCenter={option?.value === donorHomeCenter || option?.isHomeCenter}
                    isSelected={selectedCenter?.key === option.key}
                    centerData={option}
                    favourites={favourites}
                  />
                ))
              )}
            </StyledCardLocationsDiv>

            <StyledShowMoreContainer>
              {showMore && <PrimaryButton fluid={isMobileWidth} variant='outline_blue' text={t('showMore')} onClick={showMoreCenters} />}
            </StyledShowMoreContainer>
            {centerChecked ? (
              <StyledButtonsContainer id='fixedButton'>
                <PrimaryButton
                  fluid={isMobileWidth}
                  text={t('selectCenter')}
                  onClick={() => {
                    handleSelection(selectedCenter)
                    setIsChangeLocationModal(false)
                  }}
                />
              </StyledButtonsContainer>
            ) : visibleCenters?.length > 0 ? (
              allowZipcodeSearch && !favourites ? (
                <StyledButtonsContainer id='fixedButton'>
                  <PrimaryButton fluid={isMobileWidth} text={t('search')} type='submit' />
                </StyledButtonsContainer>
              ) : (
                <StyledButtonsContainer id='fixedButton'>
                  <PrimaryButton fluid={isMobileWidth} text={t('selectCenter')} type='submit' />
                </StyledButtonsContainer>
              )
            ) : favCenterList?.length > 0 ? null : (
              <StyledButtonsContainer id='fixedButton'>
                <PrimaryButton fluid={isMobileWidth} text={t('preferences')} onClick={handleRedirect} />
              </StyledButtonsContainer>
            )}
          </form>
        )}
      />
    </StyledSearchLocationContainer>
  )
}

const mapStateToProps = (state) => ({
  searchedCenters: state.centers?.getZipcodeSearchCentersSuccess?.data,
  searchCentersLoading: state.centers?.getZipcodeSearchCentersLoading
})

const mapDispatchToProps = (dispatch) => ({
  getZipcodeSearchCenters: (searchStr, isDIS8Center, searchType) => {
    dispatch(CentersActions.getZipcodeSearchCenters(searchStr, isDIS8Center, searchType))
  },
  getAllCenters: (sfid) => dispatch(CentersActions.getAllCenters(sfid))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchLocation)
