import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { StyledAppointmentType, StyledAppointmentTypeContainer, StyledAppointmentTypeSelection, FirstTimeDonortext } from './styled'
import DropdownSelect from '../../Inputs/DropdownSelect'

const AppointmentType = ({
  appointment,
  handleAppointment,
  edit,
  defaultAppointmentType,
  donor,
  centerSlots,
  centerSlotsFailure,
  centerSlotsLoading,
  inquiry
}) => {
  const { t, i18n } = useTranslation('AppointmentTypeAndPlayroom', 'Children')

  const options = [
    { key: 'PHYSICAL', value: 'PHYSICAL', text: t('PhysicalWithDonation'), disabled: false },
    {
      key: 'DONATION',
      value: 'DONATION',
      text: t('donation'),
      disabled: !donor?.pdn || (donor?.pdn && !inquiry?.lastPhysicalDate) ? true : false
    }
  ]

  const [isAppointmentChanged, setIsAppointmentChanged] = useState(false)

  const getDonationType = (value, isChanged) => {
    handleAppointment(
      !isChanged
        ? {
            ...appointment,
            appointmentType: value
          }
        : {
            ...appointment,
            startDateTime: moment(appointment.startDateTime).format('YYYY-MM-DDT00:00:00'),
            appointmentType: value,
            startTime: null,
            centerSfid: donor?.donationCenter,
            alternateCenterSelected: false
          }
    )
    setIsAppointmentChanged(true)
  }

  useEffect(() => {
    if (!centerSlotsLoading && isAppointmentChanged) {
      setIsAppointmentChanged(false)
    }
  }, [centerSlots])

  useEffect(() => {
    if (!centerSlotsLoading) {
      setIsAppointmentChanged(false)
    }
  }, [centerSlotsLoading])

  return (
    <StyledAppointmentType>
      <StyledAppointmentTypeContainer>
        <p>
          <strong>{t('appointmentType')}</strong>
        </p>
        <StyledAppointmentTypeSelection>
          <DropdownSelect
            placeholder={t('select')}
            options={options}
            handleOnChange={getDonationType}
            defaultValue={
              edit
                ? appointment.appointmentType === 'PHYSICAL'
                  ? t('PhysicalWithDonation')
                  : t('donation')
                : defaultAppointmentType === 'PHYSICALONLY' || options[1].disabled
                ? t('PhysicalWithDonation')
                : t('donation')
            }
            defaultOption={
              edit
                ? appointment.appointmentType === 'PHYSICAL'
                  ? options[0].value
                  : options[1].value
                : defaultAppointmentType === 'PHYSICALONLY' || options[1].disabled
                ? options[0].value
                : options[1].value
            }
            width={301}
          />
        </StyledAppointmentTypeSelection>
        {!donor?.pdn || (donor?.pdn && !inquiry?.lastPhysicalDate) ? (
          <FirstTimeDonortext>{t('firsttimedonortext')}</FirstTimeDonortext>
        ) : null}
      </StyledAppointmentTypeContainer>
    </StyledAppointmentType>
  )
}

export default AppointmentType
