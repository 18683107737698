import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { devices } from '../../../utils/styledComponentHelpers'
import SearchLocation from '../../Dashboard/SearchLocation'
import CentersActions from '@takedapdt/biolife-core/src/Stores/Centers/Actions'
import { mapOptionsByState, sortCentersAlphabetically } from '../../../Helpers/Utils'
import { TabPane, Tab, Icon } from 'semantic-ui-react'
import './styles.scss'

const StyledModalInnerDiv = styled.div`
  display: flex;
  padding-top: 5rem;
  flex-direction: column;
  height: 71.2rem;
  width: 83.6rem;
  overflow-y: scroll;
  ${devices.mobile} {
    width: 37.3rem;
    max-height: 66rem;
  }
  /* For browsers that support 'scrollbar-*' properties */
  @supports (scrollbar-width: auto) {
    scrollbar-width: 0.4rem;
  }
  /* Otherwise, use '::-webkit-scrollbar-*' pseudo-elements */
  @supports selector(: : -webkit-scrollbar) {
    &::-webkit-scrollbar {
      width: 0.4rem;
    }
  }
  .SearchInputButton .FieldLabel div {
    font-family: var(--font-family-bold) !important;
  }
`

const StyledFindLocationHeader = styled.div`
  padding-bottom: 1.6rem;
  p {
    width: fit-content;
    margin: auto;
    padding: 0.4rem 0.8rem;
    background: var(--color-medium-shade-gray);
    font-size: var(--caption-mobile-font-size);
  }
`

const AppointmentCenterSearch = ({
  appointment,
  handleAppointment,
  getFavoriteCenterLoading,
  favCentersLoading,
  centers,
  donor,
  center,
  open,
  setAppointment,
  getFavoriteCenters,
  favoriteCenterList,
  getAppointmentCenter,
  setIsChangeLocationModal,
  defaultCenterName,
  setDefaultCenterName,
  edit
}) => {
  const { t } = useTranslation('CenterSelection')

  /**
   * @description Updates the centers based the centers State and sorts them alphabetically
   * @returns the sorted center data state wise
   */
  const preparedOptions = async (centers) => {
    const res = await mapOptionsByState(centers)
    const data = sortCentersAlphabetically(res)
    return data
  }
  const [centerOptions, setCenterOptions] = useState([])
  const [favOptions, setFavOptions] = useState([])
  const [centerList, setCenterList] = useState(centers)

  useEffect(() => {
    preparedOptions(centers).then((res) => {
      setCenterOptions(res)
    })
  }, [centers])

  const homeCenter = centers?.filter((center) => center?.sfid === donor?.defaultCenter?.sfid)

  useEffect(() => {
    const favCenterList = favoriteCenterList?.filter((center) => centers.some((centers) => centers.sfid === center.sfid))
    if (homeCenter) {
      const updatedHomeCenter = { ...homeCenter[0], isHomeCenter: true }
      favCenterList?.push(updatedHomeCenter)
    }
    favCenterList?.sort((a, b) => a.name.localeCompare(b.name))
    let uniqueFavCenterList = favCenterList?.reduce((acc, obj) => {
      if (!acc?.some((o) => o?.sfid === obj?.sfid)) {
        acc.push(obj)
      }
      return acc
    }, [])

    preparedOptions(uniqueFavCenterList).then((res) => {
      setFavOptions(res)
    })
  }, [favoriteCenterList])

  useEffect(() => {
    if (donor?.sfid) {
      getFavoriteCenters(donor?.sfid)
    }
  }, [donor, open])
  /**
   * @description Updates the appointment object based on the selected value.
   * Calls getAppointmentCenter and handleAppointment functions.
   * @param {Object} value.value - The appointmnent details value
   */
  const handleSelection = (value) => {
    getAppointmentCenter(value.value)
    const selectedCenter = value ? `${value.name} Center` : ''
    handleAppointment({
      ...appointment,
      stateCode: value.stateCode,
      center: selectedCenter,
      centerSfid: value.value,
      centerCity: value.city,
      centerName: value.centerName,
      name: value.name,
      address: value.address,
      telephone: value.telephone,
      time: null,
      startTime: null,
      alternateCenterSelected: false
    })
  }

  useEffect(() => {
    setCenterList(centers)
    setDefaultCenterName(center ? center.data : '')
  }, [center])

  const FindLocationComponent = () => {
    return (
      <>
        <TabPane attached={false}>
          {edit && (
            <SearchLocation
              fieldLabel={t('searchByLoc')}
              fieldColor={'bl_blue'}
              options={centerOptions}
              centers={centers}
              allCenters={centers}
              favCenterList={favoriteCenterList}
              placeholderVal={t('enterStateOrCenterName')}
              handleOnChange={handleSelection}
              defaultValue={defaultCenterName?.name}
              centerID={appointment?.centerSfid || donor?.defaultCenter?.sfid}
              donorHomeCenter={donor?.defaultCenter?.sfid}
              allowZipcodeSearch={appointment?.appointmentType === 'DONATION' ? true : false}
              setIsChangeLocationModal={setIsChangeLocationModal}
              favourites={false}
            ></SearchLocation>
          )}
        </TabPane>
      </>
    )
  }
  const FavouritesComponent = () => {
    return (
      <TabPane attached={false}>
        {edit && !getFavoriteCenterLoading && (
          <SearchLocation
            fieldLabel={t('searchByLoc')}
            fieldColor={'bl_blue'}
            options={favOptions}
            centers={favoriteCenterList}
            allCenters={centers}
            favCenterList={favoriteCenterList}
            placeholderVal={t('enterStateOrCenterName')}
            handleOnChange={handleSelection}
            defaultValue={defaultCenterName?.name}
            centerID={appointment?.centerSfid || donor?.defaultCenter?.sfid}
            donorHomeCenter={donor?.defaultCenter?.sfid}
            allowZipcodeSearch={appointment?.appointmentType === 'DONATION' ? true : false}
            setIsChangeLocationModal={setIsChangeLocationModal}
            favourites={true}
          ></SearchLocation>
        )}
      </TabPane>
    )
  }

  const panes = [
    {
      menuItem: t('findLocation'),
      render: () => <FindLocationComponent />
    },
    {
      menuItem: t('Favorites'),
      render: () => <FavouritesComponent />
    }
  ]

  return (
    <StyledModalInnerDiv>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} className='ChangeLocationTabs' defaultActiveIndex={0} />
    </StyledModalInnerDiv>
  )
}

const mapStateToProps = (state) => ({
  favoriteCenterList: state?.centers?.favouriteCenters,
  getFavoriteCenterLoading: state?.centers?.getFavoriteCenterInfoLoading
})

const mapDispatchToProps = (dispatch) => ({
  getAppointmentCenter: (sfid) => dispatch(CentersActions.getAppointmentCenter(sfid)),
  getFavoriteCenters: (donorSfid) => dispatch(CentersActions.getFavoriteCenterInfo(donorSfid))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentCenterSearch)
