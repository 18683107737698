import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import importer from 'common/importer'
import { Image } from 'semantic-ui-react'
import { useParams, useLocation } from 'react-router-dom'
import { useScreenSize } from '../Contexts/ResponsiveContextProvider'

const CentersActions = importer('Stores/Centers/Actions')
const PublicContentActions = importer('Stores/PublicContent/Actions')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const Breadcrumbs = importer('Components/Breadcrumbs')
const SliderCarousel = importer('Components/SliderCarousel')
const Loader = importer('Components/Loaders/LoaderSpinner')

const CenterDetailsContainer = ({
  getCenterInfo,
  centerInfo,
  getCenterInfoFailure,
  getCenterDetailsPage,
  centerDetailsPageData,
  centerDetailsMetaData,
  centerDetailsPageLoading
}) => {
  const { isMobileWidth } = useScreenSize()
  const localBusinessSchema = { '@context': 'https://schema.org', '@type': 'MedicalBusiness', openingHoursSpecification: [] }
  const [breadcrumbsData, setBreadcrumbsData] = useState()
  const [sliderBannerData, setSliderBannerData] = useState()
  const params = useParams()
  const location = useLocation()
  useEffect(() => {
    if (params.centerSlug && params.locationSlug) {
      if (params.locationSlug.indexOf(' ') > -1 || params.centerSlug.indexOf(' ') > -1 || params.centerSlug.endsWith('-')) {
        const updatedURL = `/locations/${params.locationSlug.split(' ').join('-')}/${params.centerSlug.split(' ').join('-')}`

        // Because of splitting based on space, if space is present at end then the last character is '-', 
        // Hence, this needs to be removed.
        if (updatedURL.endsWith('-')) {
          window.location.replace(updatedURL.slice(0, -1))
        } else {
          window.location.replace(updatedURL)
        }
      } else if (params.locationSlug.indexOf('-') > -1) {
        // only checked for stateName as api doesnt accept '-' in stateName payload
        if (getCenterInfoFailure) {
          window?.location.replace(`/locations/${params?.locationSlug?.toLowerCase()}`)
        } else {
          getCenterInfo(params.centerSlug, params.locationSlug.split('-').join(' '))
        }
      } else {
        // Check if center call is failing, then redirect to state page
        if (getCenterInfoFailure) {
          window?.location.replace(`/locations/${params?.locationSlug?.toLowerCase()}`)
        } else {
          getCenterInfo(params.centerSlug, params.locationSlug)
        }
      }
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location, getCenterInfoFailure])

  const buildOpeningHoursSchema = (facilityHours) => {
    const groupedHours = {}
    // Group objects by openingTime and closingTime
    facilityHours?.forEach((item) => {
      const key = `${item.openingTime}-${item.closingTime}`
      if (!groupedHours[key]) {
        groupedHours[key] = {
          opens: item.openingTime ? item.openingTime.slice(0, item.openingTime.lastIndexOf(':')) : null,
          closes: item.closingTime ? item.closingTime.slice(0, item.closingTime.lastIndexOf(':')) : null,
          dayOfWeek: [item.weekDayName]
        }
      } else {
        groupedHours[key]?.dayOfWeek?.push(item.weekDayName)
      }
    })
  }

  useEffect(() => {
    let script
    if (centerInfo) {
      localBusinessSchema['name'] = centerInfo?.name
      localBusinessSchema['image'] = centerInfo?.webImageUrl
      localBusinessSchema['mwebimage']=centerInfo?.mwebImageUrl
      localBusinessSchema['url'] = window.location.href
      localBusinessSchema['telephone'] = centerInfo?.telephone
      localBusinessSchema['address'] = {
        '@type': 'PostalAddress',
        streetAddress: centerInfo?.addressLine1,
        addressLocality: centerInfo?.city,
        addressRegion: centerInfo?.stateCode,
        postalCode: centerInfo?.zipcode,
        addressCountry: 'US'
      }
      localBusinessSchema['geo'] = { '@type': 'GeoCoordinates', latitude: centerInfo?.latitude, longitude: centerInfo?.longitude }
      localBusinessSchema['openingHoursSpecification'] = buildOpeningHoursSchema(centerInfo?.hoursOfOperation)

      script = document.createElement('script')
      script.type = 'application/ld+json'
      script.text = JSON.stringify(localBusinessSchema)
      document.head.appendChild(script)
    }

    return () => {
      if (script) {
        document.head.removeChild(script)
      }
    }
  }, [])

  useEffect(() => {
    let breadcrumbs, sliderBannerImages
    breadcrumbs = centerDetailsPageData?.find((item) => item.type === 'breadcrumb')
    sliderBannerImages = centerDetailsPageData?.filter((item) => item.type === 'slider_banner_component')
    setBreadcrumbsData(breadcrumbs)
    setSliderBannerData(sliderBannerImages)
  }, [centerDetailsPageData])

  useEffect(() => {
    if (centerInfo) {
      getCenterDetailsPage(centerInfo?.centerNum)
    }
  }, [centerInfo])

  return (
    <>
      {centerDetailsPageLoading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>{centerDetailsMetaData?.meta_title}</title>
            <meta name='description' content={centerDetailsMetaData?.meta_description} />
            <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
          </Helmet>
          {sliderBannerData?.length > 0 ? (
            <SliderCarousel>
              {
              isMobileWidth && sliderBannerData?.find(image=>image.field_component_mobile_image) ? sliderBannerData?.map(({ field_component_mobile_image }) => (
                <Image src={field_component_mobile_image?.url } alt={field_component_mobile_image?.alt} />
              )) :              
              sliderBannerData?.map(({ field_component_image }) => (
                <Image src={field_component_image?.url} alt={field_component_image?.alt} />
              ))}
            </SliderCarousel>
          ) : null}
          <Breadcrumbs
            breadcrumbToggle={centerDetailsMetaData?.field_content_breadcrumb}
            breadcrumbMobileToggle={centerDetailsMetaData?.field_mobile_breadcrumb}
            levelOnePropsTitle={breadcrumbsData?.field_column_1_url?.title}
            clickableLevelOne={true}
            levelTwoPropsTitle={centerInfo?.stateCode}
            clickableLevelTwo={true}
            levelThreePropsTitle={centerInfo?.name}
          />
          {centerDetailsPageData?.map((component, index) => {
            if (component.type === 'breadcrumb' || component.type === 'slider_banner_component') {
              return
            }
            return renderDrupalComponent(component, index)
          })}
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  centerInfo: state.centers?.getCenterInfoSuccess?.data,
  getCenterInfoFailure: state.centers?.getCenterInfoFailure,
  centerDetailsPageData: state.publicContent?.getCenterDetailsPageSuccess?.contents,
  centerDetailsMetaData: state.publicContent?.getCenterDetailsPageSuccess?.metadata,
  centerDetailsPageLoading: state.publicContent?.getCenterDetailsPageLoading
})

const mapDispatchToProps = (dispatch) => ({
  getCenterInfo: (locationName, locationState) => dispatch(CentersActions.getCenterInfo(locationName, locationState)),
  getCenterDetailsPage: (centerSfid) => dispatch(PublicContentActions.getCenterDetailsPage(centerSfid))
})

export default connect(mapStateToProps, mapDispatchToProps)(CenterDetailsContainer)
