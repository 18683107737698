import styled from 'styled-components'
import { devices } from '../../../utils/styledComponentHelpers'

export const AppointmentSlotsGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.$availableSlots < 6 ? `repeat(${props.$availableSlots}, 1fr)` : 'repeat(6, 1fr)')};
  grid-gap: 2.4rem 1.6rem;
  justify-items: center;
  align-items: center;
  ${devices.mobile} {
    grid-template-columns: ${(props) => (props.$availableSlots < 3 ? `repeat(${props.$availableSlots}, 1fr)` : 'repeat(3, 1fr)')};
    grid-gap: 1.6rem 2.4rem;
  }
`
export const AppointmentSlot = styled.div`
  width: 8.4rem;
  height: 3.5rem;
  border: ${(props) => (props.$selectedSlot ? 'none' : '0.1rem solid var(--color-rich-black)')};
  border-radius: 1.8rem;
  text-align: center;
  padding: 0.8rem auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.$selectedSlot ? 'var(--color-orange)' : 'transparent')};
  cursor: pointer;
`
export const AvailableSlotTime = styled.input`
  display: none;
  font-size: var(--caption-mobile-font-size);
  &:checked + label {
    color: var(--color-white)
  }
}
`
export const AvailableSlotTimeLabel = styled.label`
  font-size: var(--caption-mobile-font-size);
  cursor: pointer;
`
export const AppointmentSelectionErrorContainer = styled.div`
  text-align: center;
  ${devices.mobile} {
    padding: 0 6.1rem;
  }
`
export const LoadingState = styled.div`
  padding: 2rem 0;
`
