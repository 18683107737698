// What if bot throws an Array (chatbotResponseFailure)

import React, { useEffect, useState, useRef } from 'react'
import { throttle } from 'lodash'
import { cssAppliedContent, extractDataFromLocalStorage } from '../utils'
import { useSelector, useDispatch } from 'react-redux'
import chatbotActions from '@takedapdt/biolife-core/src/Stores/Chatbot/Actions'
// import chatbotActions from '../../../../../biolife-core/src/Stores/Chatbot/Actions'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'
import {
  MessageAreaMainContainer,
  MessageAreaContainer,
  StyledTextArea,
  BotMessage,
  UserMessage,
  InputFieldContainer,
  BotMessageContainer,
  BotMessageImgContainer,
  BotMessageImg,
  UserMessageContainer,
  MainBotMessageContainer,
  BotTextContainerWithPills,
  PillsContainer,
  Pills,
  ContainerEndChat,
  EndChatText,
  EndChatSpaceDiv,
  LoaderDiv,
  BotButtonLinkDisplayDiv,
  BotButtonLinkText,
  BotButtonLinkTextContainer,
  ButtonLinkRightArrow,
  BotButtonLinkDescText,
  CustomPayloadContainer,
  CustomPayloadHTMLTextDiv,
  CustomPayloadHTMLLinksDiv,
  ImageContainer
} from './messageArea.styled'
import ButtonLinkRightArrowOrange from '../../../../assets/media/icons/ButtonLinkRightArrowOrange.svg'
import ButtonLinkRightArrowImg from '../../../../assets/media/icons/ButtonLinkRightArrow.svg'
import ChatSendIcon from '../../../../assets/media/icons/ChatbotSendIcon.svg'
import BotMessageIcon from '../../../../assets/media/icons/BotMessageIcon.svg'
import ChatbotBrokenScreen from '../ChatbotBrokenScreen'
function MessageArea({
  messagesArr,
  messageArrSetter,
  createBotText,
  createUserText,
  createLoadingBotMessage,
  resetBotState,
  resetChatbot,
  commAlreadyStarted,
  changeCommAlreadyStarted,
  chatbotData,
  changeChatbotData,
  changeChatbotSelectedLanguage,
  selectedLanguage,
  currentSessionID,
  currentIntent,
  resetTimeouts
}) {
  let initialStateChatbotData = {
    ...chatbotData
    // localeId: selectedLanguage,
    // chatStarted: false,
    // chatEnded: false,
    // sessionTimout: false
  }
  const [showMoreMessage, setShowMoreMessage] = useState(false)
  const isLastBotMessageVisibleOnScreen = useRef(false)
  const isMessageAreaScrollable = useRef(false)
  // const notOnMount = useRef(false)
  const messageAreaScroll = useRef('')
  const latestMessageFromBot = useRef()
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const getChatbotResponseLoading = useSelector((state) => state.chatbot.getChatbotResponseLoading)
  const chatbotResponseSuccess = useSelector((state) => state.chatbot.getChatbotResponseSuccess)
  const chatbotResponseFailure = useSelector((state) => state.chatbot.getChatbotResponseFailure)
  const dispatch = useDispatch()
  const [selectedPill, setSelectedPill] = useState('')
  const [textInputPillNotSelected, setTextInputPillNotSelected] = useState('')
  const [backendData, setBackendData] = useState({})
  const [disableTextAreaAtLoad, setDisableTextAreaAtLoad] = useState(false)

  useEffect(() => {
    if (
      commAlreadyStarted &&
      currentIntent.current &&
      currentSessionID.current &&
      messagesArr &&
      messagesArr.length &&
      !messagesArr.some((el) => el.type && el.type === 'loading')
    ) {
      const dataToSave = {
        chatSessionID: currentSessionID.current,
        currentIntent: currentIntent.current,
        backendData: backendData,
        messageData: messagesArr
      }
      localStorage.setItem('chatbotData', JSON.stringify(dataToSave))
    }
  }, [messagesArr])

  useEffect(() => {
    if (localStorage.getItem('chatbotData') && localStorage.getItem('chatbotData').length) {
      const extractedLocalStorageData = extractDataFromLocalStorage(localStorage.getItem('chatbotData'))

      if (currentSessionID.current && extractedLocalStorageData.chatSessionID !== currentSessionID.current) {
        localStorage.removeItem('chatbotData')
      }
    }
  }, [currentSessionID.current])

  // useEffect(() => {
  //   console.log('OnChange of MessageArea')
  //   if (messageAreaScroll.current.scrollHeight > messageAreaScroll.current.clientHeight) {
  //     console.log('OnChange of MessageArea?> Changed Into Scrollable MessageArea')
  //     isMessageAreaScrollable.current = true
  //   }
  // }, [messageAreaScroll.current])

  // useEffect(() => {
  //   // console.log('messageAreaScroll.scrollHeight>>', messageAreaScroll.scrollHeight)
  //   console.log('entry>> isMessageAreaScrollable.current>>', isMessageAreaScrollable.current)
  //   if (isMessageAreaScrollable.current) {
  //     // Work only if MessageArea is scrollable
  //     console.log('latestMessageFromBot.current>>@0', latestMessageFromBot.current)
  //     if (latestMessageFromBot.current) {
  //       scrollIntoView()
  //       // console.log('latestMessageFromBot.current>> latestMessageFromBot is now null', latestMessageFromBot)
  //       // latestMessageFromBot.current = null
  //     }
  //     // const options = {
  //     //   threshold: 0
  //     //   // rootMargin: '-300px'
  //     // }
  //     // const callback = ([entry]) => {
  //     //   console.log('entry>>', entry, entry.isIntersecting)
  //     //   if (isLastBotMessageVisibleOnScreen.current === null) {
  //     //     isLastBotMessageVisibleOnScreen.current = isLastBotMessageVisibleOnScreen.current ?? entry.isIntersecting
  //     //   }
  //     //   if (!isLastBotMessageVisibleOnScreen.current) {
  //     //     // if isLastBotMessageVisibleOnScreen.current is false
  //     //     setShowMoreMessage(true)
  //     //   }
  //     // }
  //     // const observer = new IntersectionObserver(callback, options)
  //     // if (latestMessageFromBot.current) {
  //     //   console.log('latestMessageFromBot.current>>@Intersection', latestMessageFromBot.current)
  //     //   observer.observe(latestMessageFromBot.current)
  //     // }

  //     // return () => {
  //     //   console.log('Component Unmount for unoberving is happening!!')
  //     //   // if(latestMessageFromBot.current){
  //     //   //   observer.unobserve(latestMessageFromBot.current)
  //     //   // }
  //     // }
  //   }
  // }, [messagesArr])

  useEffect(() => {
    // console.log('currentIntent.current>>', currentIntent.currentIntent)
    if (currentIntent.current === 'FinalIntent' || currentIntent.current === 'FinalIntent_Sp') {
      sendEndChatDataNormalFlow()
    }
  }, [currentIntent.current])

  useEffect(() => {
    // commAlreadyStarted is a boolean value and changes only when there is no localStorage Data
    if (!localStorage.getItem('chatbotData') || !localStorage.getItem('chatbotData').length) {
      if (commAlreadyStarted) {
        changeChatbotData({
          ...initialStateChatbotData,
          chatStarted: false
        })
      }
    }
  }, [commAlreadyStarted])

  useEffect(() => {
    // console.log('OnChange of MessageArea')
    // if (messageAreaScroll.current.scrollHeight > messageAreaScroll.current.clientHeight) {
    //   // console.log('OnChange of MessageArea?> Changed Into Scrollable MessageArea')
    //   if (isLastBotMessageVisibleOnScreen.current) isLastBotMessageVisibleOnScreen.current = null
    //   // latestMessageFromBot.current setting null on loading new Message
    //   isMessageAreaScrollable.current = true
    // }
    if (chatbotResponseSuccess && Object.keys(chatbotResponseSuccess)) {
      const intent = chatbotResponseSuccess?.intentName || ''
      const messageResponse = chatbotResponseSuccess?.message

      if (
        currentSessionID.current &&
        chatbotResponseSuccess?.chatSessionID &&
        chatbotResponseSuccess?.chatSessionID !== currentSessionID.current
      ) {
        currentSessionID.current = chatbotResponseSuccess?.chatSessionID
      } else if (!currentSessionID.current) {
        if (!localStorage.getItem('chatbotData')) {
          currentSessionID.current = chatbotResponseSuccess?.chatSessionID
        }
      }
      setBackendData((prev) => {
        return {
          ...prev,
          donorStatus: chatbotResponseSuccess?.donorStatus || null,
          chatSessionID: chatbotResponseSuccess?.chatSessionID || null,
          fallbackCount: chatbotResponseSuccess?.fallbackCount || null
        }
      })
      if (!messageResponse || !messageResponse.length) return
      if (messageAreaScroll.current.scrollHeight > messageAreaScroll.current.clientHeight) {
        messageResponse[0]['ref'] = true
      }

      // console.log('After Success>>', newData)
      // ********Should Work without below two lines***********
      selectedPill && setSelectedPill('')
      textInputPillNotSelected && setTextInputPillNotSelected('')
      currentIntent.current = intent
      setDisableTextAreaAtLoad(false)
      createBotText(messageResponse)
    }
  }, [chatbotResponseSuccess])

  useEffect(() => {
    if (getChatbotResponseLoading) {
      latestMessageFromBot.current = null
    }
  }, [getChatbotResponseLoading])

  useEffect(() => {
    initialStateChatbotData = { ...chatbotData }
  }, [chatbotData])

  useEffect(() => {
    if (messagesArr && !messagesArr.length && localStorage.getItem('chatbotData') && localStorage.getItem('chatbotData').length) {
      const extractedLocalStorageData = extractDataFromLocalStorage(localStorage.getItem('chatbotData'))
      extractedLocalStorageData.backendData && setBackendData(extractedLocalStorageData.backendData)
      extractedLocalStorageData.messageData &&
        extractedLocalStorageData.messageData.length &&
        messageArrSetter(extractedLocalStorageData.messageData)
      currentSessionID.current = extractedLocalStorageData.chatSessionID || ''
      currentIntent.current = extractedLocalStorageData.currentIntent || ''
      // To set communication is already started as localstorage data is available
      changeCommAlreadyStarted()
    } else {
      if (!commAlreadyStarted) {
        setBackendData({})
        messageArrSetter([])
        fetchMessage('hi')
        currentIntent.current = ''
      }
    }
    initialStateChatbotData = { ...chatbotData }
  }, [])

  useEffect(() => {
    if (resetChatbot) {
      messageArrSetter([])
      fetchMessage('hi')
      setBackendData({})
      resetBotState(false)
    }
  }, [resetChatbot])

  const sendEndChatDataNormalFlow = () => {
    dispatch(
      chatbotActions.getChatbotResponse({
        ...chatbotData,
        ...backendData,
        chatEnded: true,
        inputText: ''
      })
    )
  }

  const pillsNeedsToBeFullSize = ({ pills = [], index }) => {
    let result = false
    const incrementingIndex = index + 1
    if (pills.length) {
      if (isMobileWidth && incrementingIndex % 2 !== 0) {
        const nextPillData = pills[incrementingIndex]
        if (nextPillData && nextPillData?.text?.length > 30) {
          result = true
        }
      } else if ((!isTabletWidth || !isMobileWidth) && incrementingIndex % 2 !== 0) {
        const nextPillData = pills[incrementingIndex]
        if (nextPillData && nextPillData?.text?.length > 37) {
          result = true
        }
      }

      return result
    }
  }

  const fetchMessage = (inputText) => {
    let data = { ...backendData, inputText, ...chatbotData }
    if (inputText === 'hi') {
      // Used at first message sent or at reset for chatbotData default Language
      data = { ...data, ...backendData, chatStarted: true, localeId: 'en_US' }
    }
    if (currentIntent.current === 'LanguageSelection' || currentIntent.current === 'LanguageSelection_Sp') {
      data = { ...data, ...backendData, inputText, localeId: inputText === 'Español' ? 'es_US' : 'en_US' }
    }
    selectedPill && setSelectedPill('')
    textInputPillNotSelected && setTextInputPillNotSelected('')
    dispatch(chatbotActions.getChatbotResponse(data))
    setDisableTextAreaAtLoad(true)
    createLoadingBotMessage()
  }

  const pillSelected = (pillValue) => {
    // After the Slected Value is set the user Message picks the selected pill and
    // createUserMessage turns it into User Message and pills disappear.
    setSelectedPill(pillValue)
    userTextHandler(pillValue)
  }

  const userTextHandler = (userMessage) => {
    // latestMessageFromBot.current null so that new value could be set
    // latestMessageFromBot.current = null
    let textToBeSent = ''
    if (userMessage.value) {
      textToBeSent = userMessage.value
    } else if (userMessage.length) {
      textToBeSent = userMessage
    } else {
      return null
    }

    if (currentIntent.current === 'LanguageSelection' || currentIntent.current === 'LanguageSelection_Sp') {
      changeChatbotSelectedLanguage(textToBeSent)
      // in this case selected language state is being changed so fetch Message is in the useEffect
    }

    if (!commAlreadyStarted) {
      changeCommAlreadyStarted()
    }

    if (!selectedPill && textToBeSent.length) {
      // Pills are there for the user to select
      // Case when pill is not selected but user types the response
      setTextInputPillNotSelected(textToBeSent)
    }
    const userMessageDetails = { content: userMessage?.text || textToBeSent, contentType: 'PlainText' }
    createUserText(userMessageDetails)

    fetchMessage(textToBeSent)
  }

  const calcPillsCount = (pillsArr, pillTextLength) => {
    let count = 0
    for (let one of pillsArr) {
      if (isMobileWidth) {
        if (one?.text?.length > 30) {
          count++
        }
        count++
      } else if (!isMobileWidth || !isTabletWidth) {
        if (one?.text?.length > 37) {
          count++
        }
        count++
      }
    }
    return count
  }

  const handleCustomPayload = ({ htmlText, links }) => {
    let result = { links: null, htmlText: null }
    if (htmlText.length) {
      result = {
        htmlText: (
          <BotMessage
            dangerouslySetInnerHTML={{
              // cssAppliedContent needed because the padding is provided to message.
              __html: cssAppliedContent(htmlText)
            }}
            /* ref={latestMessageFromBot} */
            $messageCount={htmlText?.length}
          />
        )
      }
    }
    if (links && links.length) {
      result = {
        ...result,
        links:
          (links &&
            links.length &&
            links.map((linkData) => (
              <BotButtonLinkDisplayDiv
                onClick={() => {
                  window.open(linkData.url, '_blank')
                }}
              >
                <BotButtonLinkTextContainer>
                  <BotButtonLinkText>{linkData.label || ''}</BotButtonLinkText>
                  <ImageContainer>
                    <ButtonLinkRightArrow id='rightArrow' src={ButtonLinkRightArrowImg} />
                    <ButtonLinkRightArrow id='hoverRightArrow' src={ButtonLinkRightArrowOrange} />
                  </ImageContainer>
                </BotButtonLinkTextContainer>
                {(linkData.desc && <BotButtonLinkDescText>{linkData.desc || ''}</BotButtonLinkDescText>) || <></>}
              </BotButtonLinkDisplayDiv>
            ))) ||
          null
      }
    }
    return result
  }

  const handleMessageContentType = (message, showPillsOrNot) => {
    switch (message.contentType) {
      case 'PlainText': {
        return message?.content === '...' ? (
          <BotMessage $loaderMessage={message?.content === '...'}>
            <LoaderDiv />
          </BotMessage>
        ) : (
          <BotMessage ref={latestMessageFromBot} $messageCount={message?.content?.length}>
            {message?.content || ''}
          </BotMessage>
        )
        break
      }
      case 'ImageResponseCard': {
        const pills = message?.imageResponseCard?.buttons || []
        if (textInputPillNotSelected.length || selectedPill.length) return <></>
        return (
          (showPillsOrNot && pills && pills.length && (
            <PillsContainer>
              {pills.map((pill, index) => {
                return (
                  <Pills
                    $text={pill?.text || ''}
                    onClick={() => pillSelected(pill || '')}
                    $intent={currentIntent.current}
                    $wordLength={pill?.text?.length}
                    $pillsCount={calcPillsCount(pills, pill?.text?.length)}
                    $needToBeFullSized={pillsNeedsToBeFullSize({ pills, index })}
                  >
                    {pill?.text || ''}
                  </Pills>
                )
              })}
            </PillsContainer>
          )) || <></>
        )
        break
      }
      case 'CustomPayload': {
        const result = handleCustomPayload(message?.content?.customPayload)
        return (
          <CustomPayloadContainer>
            {result.htmlText ? (
              <CustomPayloadHTMLTextDiv $linksData={result.links == null}>{result.htmlText}</CustomPayloadHTMLTextDiv>
            ) : (
              <></>
            )}
            {result.links ? <CustomPayloadHTMLLinksDiv>{result.links}</CustomPayloadHTMLLinksDiv> : <></>}
          </CustomPayloadContainer>
        )
        break
      }
    }
  }

  // const scrollIntoView = (ref) => {
  //   console.log('latestMessageFromBot.current>>@1', latestMessageFromBot.current)
  //   latestMessageFromBot.current?.scrollIntoView({ behaviour: 'smooth' })
  // }

  // const handleWindowScroll = (e) => {
  //   console.log(
  //     'Into Handdling Window',
  //     messageAreaScroll.current.scrollHeight,
  //     messageAreaScroll.current.scrollTop,
  //     messageAreaScroll.current.scrollHeight - messageAreaScroll.current.scrollTop,
  //     Math.floor(messageAreaScroll.current.scrollHeight - messageAreaScroll.current.scrollTop),
  //     messageAreaScroll.current.clientHeight
  //   )
  //   // let oldScrollY = 0
  //   // let res = ''
  //   if (messageAreaScroll.current.scrollHeight > messageAreaScroll.current.clientHeight) {
  //     if (e.nativeEvent.wheelDelta > 0) {
  //       console.log('scroll up')
  //     } else {
  //       console.log('scroll down')
  //     }
  //   }

  //   if (
  //     Math.floor(messageAreaScroll.current.scrollHeight - messageAreaScroll.current.scrollTop) === messageAreaScroll.current.clientHeight
  //   ) {
  //     console.log('hit the bottom')
  //   }

  //   // oldScrollY = messageAreaScroll.current.scrollY
  //   // console.log('res>>', res, oldScrollY)
  //   // console.log('messageAreaScroll>>', messageAreaScroll.current.scrollHeight, messageAreaScroll.current.clientHeight)
  //   // e.preventDefault()
  //   // e.stopPropagation()
  // }
  // console.log('resetChat @0', resetChatbot)
  // console.log('Testing SelectedPill', selectedPill)
  // console.log('textInputPillNotSelected>>', textInputPillNotSelected)

  // console.log('messagesArr@!#@!>>', messagesArr)
  // console.log('currentIntent.current>>', currentIntent.current)
  // console.log(
  //   'messageAreaScroll>>',
  //   messageAreaScroll.current && messageAreaScroll.current.scrollHeight,
  //   messageAreaScroll.current.clientHeight,
  //   messageAreaScroll.current.scrollHeight > messageAreaScroll.current.clientHeight // Scrollable hai div
  // )
  return (
    <MessageAreaMainContainer>
      {chatbotResponseFailure && chatbotResponseFailure === 'Chatbot Broken' ? (
        <ChatbotBrokenScreen selectedLanguage={selectedLanguage} />
      ) : (
        <>
          <MessageAreaContainer
            ref={messageAreaScroll}
            onScroll={throttle(() => {
              resetTimeouts()
            }, 50)}
            /* onWheel={(e) => handleWindowScroll(e)} */
          >
            {(messagesArr &&
              messagesArr.length &&
              messagesArr.map((el, index) => {
                const showOlderPilsOrNot = index + 1 === messagesArr.length
                // console.log('el@!@#>>', el)
                return el.messageFrom && el.messageFrom === 'bot' ? (
                  <MainBotMessageContainer {...(el.ref ? (ref = { latestMessageFromBot }) : {})}>
                    <BotMessageContainer>
                      <BotMessageImgContainer>
                        <BotMessageImg src={BotMessageIcon} />
                      </BotMessageImgContainer>
                      <BotTextContainerWithPills>
                        {el.message &&
                          el.message.length &&
                          el.message.map((message) => {
                            return handleMessageContentType(message, showOlderPilsOrNot)
                          })}
                      </BotTextContainerWithPills>
                    </BotMessageContainer>
                  </MainBotMessageContainer>
                ) : (
                  <UserMessageContainer>
                    <UserMessage>{el?.message?.content}</UserMessage>
                  </UserMessageContainer>
                )
              })) || <></>}
            <div>{currentIntent.current === 'FinalIntent' || currentIntent.current === 'FinalIntent_Sp' ? <EndChatSpaceDiv /> : <></>}</div>
          </MessageAreaContainer>
          <InputFieldContainer>
            {currentIntent.current === 'FinalIntent' || currentIntent.current === 'FinalIntent_Sp' ? (
              <>
                <ContainerEndChat>
                  <EndChatText>Chat ended</EndChatText>
                </ContainerEndChat>
              </>
            ) : (
              <></>
            )}
            <StyledTextArea
              icon={ChatSendIcon}
              disabled={currentIntent.current === 'FinalIntent' || currentIntent.current === 'FinalIntent_Sp' || disableTextAreaAtLoad}
              placeholder={'Type a message'}
              iconContainerStyle={{
                componentStyle: { borderRadius: '25px', border: `1px solid var(--color-pills-light-grey)` }
              }}
              className={'styledTextArea'}
              sendFunction={userTextHandler}
            />
          </InputFieldContainer>
        </>
      )}
    </MessageAreaMainContainer>
  )
}

export default MessageArea
