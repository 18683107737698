import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import i18next from 'i18next'
import GoogleMapReact from 'google-map-react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import StarIcon from '../../../../assets/media/icons/StarIcon.svg'
import appointmentDetailsCalenderIcon from '../../../../assets/media/icons/appointmentDetailsCalenderIcon.svg'
import UITimeIcon from '../../../../assets/media/icons/UITimeIcon.svg'
import UIPhoneIcon from '../../../../assets/media/icons/UIPhoneIcon.svg'
import AddressLocationIcon from '../../../../assets/media/icons/AddressLocationIcon.svg'
import { StyledSpan, MapPinIcon, Div, DivWithFlex, Highlight2, Image, ImageDiv, Highlight5, TextComponent, SmallTitle } from './styled'
import { useLocation, useNavigate } from 'react-router-dom'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'
import LoaderSpinner from '../../../Components/Loaders/LoaderSpinner'
import PrimaryButton from '../../Buttons/PrimaryButton'
import AccordianComponent from './AccordianComponent'
import Config from '../../../Config'
import ExternalComponentSkeleton from './ExternalComponentSkeleton'
import CenterContentActions from '@takedapdt/biolife-core/src/Stores/Centers/Actions'
import AppointmentsActions from '@takedapdt/biolife-core/src/Stores/Appointments/Actions'
import { ROUTES } from '../../../NavigationService/'
import Modal from '../../Modal'
import AppointmentReschedule from '../AppointmentReschedule'
import AppointmentDetailsConfirmModal from '../AppointmentDetailsConfirmModal'
import ConfirmedAppointmentModal from '../ConfirmedAppointmentModal'
import ConfirmedCancellationModal from '../ConfirmedCancellationModal'
import { ReactComponent as HomeHeartIcon } from '../../../../assets/media/icons/home-heart.svg'
const MapMarker = ({ text }) => (
  <div style={{ textAlign: 'center' }}>
    <MapPinIcon />
  </div>
)

function AppointmentDetails({
  metadata,
  componentList,
  getUpcomingAppointments,
  donor,
  inquiry,
  apptCenter,
  getAppointmentCenter,
  getSlotsForAppointmentType,
  getDonorCentersAll,
  loggedInUser,
  rescheduleAppointment,
  rescheduleAppointmentSuccess,
  rescheduleAppointmentLoading,
  rescheduleAppointmentFailure,
  lastPhysicalDate,
  cancelAppointment,
  cancelAppointmentSuccess,
  cancelAppointmentLoading,
  cancelAppointmentFailure,
  getZipcodeSearchCenters,
  centerSlots,
  centerSlotsLoading,
  centerSlotsFailure,
  centers,
  getAllCenters,
  center,
  centerLoading,
  searchedCenters,
  isNewDonor
}) {
  let navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const [loading, setLoading] = useState(false)
  const { isMobileWidth } = useScreenSize()
  const { t } = useTranslation('AppointmentDetails')
  const [redirectTo, setRedirectTo] = useState(false)
  const [appointment, setAppointment] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [cancelledClicked, setCancelledClicked] = useState(false)
  const [openDetailConfirmationModal, setOpenDetailConfirmationModal] = useState(false)
  const [openConfirmedAppointmentModal, setOpenConfirmedAppointmentModal] = useState(false)
  const [openConfirmedCancellationModal, setOpenConfirmedCancellationModal] = useState(false)

  const toggleModal = () => setOpenModal((prevState) => !prevState)
  const toggleDetailConfirmationModal = () => setOpenDetailConfirmationModal((prevState) => !prevState)
  const toggleConfirmedAppointmentModal = () => setOpenConfirmedAppointmentModal((prevState) => !prevState)
  const toggleConfirmedCancellationModal = () => setOpenConfirmedCancellationModal((prevState) => !prevState)
  const handleAppointment = (app) => {
    setAppointment({ ...app, appointmentId: app.id || app.appointmentId })
  }
  const handleRescheduleAppointment = () => {
    getSlotsForAppointmentType(
      moment
        .utc(state?.startDateTime)
        .startOf('day')
        .format('YYYY-MM-DDT00:00:00'),
      null,
      null,
      state?.numberOfChildren || 0,
      state?.centerSfid,
      state?.appointmentType,
      state?.appointmentId || state?.id,
      donor?.sfid
    )
    getAppointmentCenter(state?.centerSfid)
    getDonorCentersAll && getDonorCentersAll(null, true, 'disDate')
  }

  useEffect(() => {
    getDonorCentersAll(null, true, 'disDate', true) // true corresponds to addNewDonorBonusInformation
    getZipcodeSearchCenters(donor?.zipCode, true, 'disdate', donor?.donationCenter, true)
  }, [])
  useEffect(() => {
    setLoading(true)
    if (!state || (state && !state.centerSfid)) setRedirectTo(true)
    if (state && state.centerSfid) getAllCenters(state?.centerSfid)
    setAppointment({ ...state, appointmentId: state.id || state.appointmentId })
    window.scrollTo(0, 0)
  }, [state])
  useEffect(() => {
    if (openModal) {
      setAppointment({ ...state, appointmentId: state.id || state.appointmentId })
      getAllCenters(state?.centerSfid)
      handleRescheduleAppointment()
    }
    if (
      !openModal &&
      !openDetailConfirmationModal &&
      !openConfirmedAppointmentModal &&
      !openConfirmedCancellationModal &&
      state &&
      state.centerSfid
    ) {
      getAllCenters(state?.centerSfid)
    }
  }, [openModal, openDetailConfirmationModal, openConfirmedAppointmentModal, openConfirmedCancellationModal])
  useEffect(() => {
    if (redirectTo) {
      navigate(ROUTES && ROUTES.DonorDashboard /* '/dashboard/donor-dashboard' */)
    }
  }, [redirectTo, navigate])
  useEffect(() => {
    if (!centerLoading && state && Object.keys(state).length) {
      setLoading(false)
    }
  }, [centerLoading, state])

  const openMap = () => {
    return window.open(`http://www.google.com/maps/place/${center?.latitude},${center?.longitude}`)
  }

  return (
    <>
      <Helmet>
        <title>{metadata?.meta_title}</title>
        <meta name='description' content={metadata?.meta_description} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      {(loading || centerLoading) && !openModal ? (
        <LoaderSpinner />
      ) : (
        <>
          <ExternalComponentSkeleton>
            <Div
              $bgColor='white_smoke'
              $borderRadius={isMobileWidth ? '0px 0px 50px 50px' : '0px 0px 150px 150px'}
              $padding='6.4rem 0rem 10rem 0rem'
            >
              <Div className='main-container'>
                <DivWithFlex $flexWrap='wrap' /*  $justifyContent={!isMobileWidth ? 'space-around' : 'start'} */>
                  {/* Center Details */}
                  <DivWithFlex $direction='column' $rowGap='0.8rem' $width={isMobileWidth ? '100%' : '50%'}>
                    <Div>
                      {!isMobileWidth ? (
                        <>
                          {state?.centerSfid === donor?.defaultCenter?.sfid && (
                            <ImageDiv>
                              <HomeHeartIcon className='HomeCenterIcon' width='24px' height='24px' fill='none' />
                            </ImageDiv>
                          )}
                          <DivWithFlex
                            $direction={!isMobileWidth ? 'column' : 'row'}
                            $justifyContent={!isMobileWidth ? 'start' : 'space-between'}
                            $margin='0.8rem 0rem 0rem 0rem'
                          >
                            <div>
                              <Highlight2 $color='titleBlue'>{`${state?.centerName || state?.name || ''}, ${state?.centerStateCode ||
                                state?.stateCode}`}</Highlight2>
                            </div>
                          </DivWithFlex>
                        </>
                      ) : (
                        <DivWithFlex
                          $direction={!isMobileWidth ? 'column' : 'row'}
                          $justifyContent={!isMobileWidth ? 'start' : 'space-between'}
                          $alignItems='flex-start'
                        >
                          <div>
                            <Highlight2 $color='titleBlue'>{`${state?.centerName || state?.name || ''}, ${state?.centerStateCode ||
                              state?.stateCode}`}</Highlight2>
                          </div>
                          {state?.centerSfid === donor?.defaultCenter?.sfid && (
                            <ImageDiv>
                              <Image src={StarIcon} alt='StarImage' $height='24px' $width='$24px' />
                            </ImageDiv>
                          )}
                        </DivWithFlex>
                      )}
                    </Div>
                    {/* Appointment Date */}
                    <DivWithFlex $direction='row' $columnGap='1rem'>
                      <ImageDiv>
                        <Image src={appointmentDetailsCalenderIcon} alt='AppointmentDetailsCalenderIcon' $height='16px' $width='16px' />
                      </ImageDiv>
                      <Highlight5 $color='rich_black' $fontFamily='var(--font-family-regular)'>
                        {moment
                          .utc(state?.startDateTime)
                          .startOf('day')
                          .format('MMMM D')}
                        ,{' '}
                        {moment
                          .utc(state?.startDateTime)
                          .startOf('day')
                          .format('YYYY')}
                      </Highlight5>
                    </DivWithFlex>
                    {/* Appointment Time */}
                    <DivWithFlex $direction='row' $columnGap='1rem'>
                      <ImageDiv>
                        <Image src={UITimeIcon} alt='UITimeIcon' $height='16px' $width='16px' />
                      </ImageDiv>
                      <Highlight5 $color='rich_black' $fontFamily='var(--font-family-regular)'>
                        {state?.startTime}
                      </Highlight5>
                    </DivWithFlex>
                    {/* Address And Phone Number And Directions Div */}
                    <Div>
                      {/* Center Address */}
                      <Div>
                        <DivWithFlex $direction='row' $columnGap='1rem' $margin='2.4rem 0rem 0rem 0rem'>
                          <ImageDiv>
                            <Image src={AddressLocationIcon} alt='AddressLocationIcon' $height='19.5px' $width='16px' />
                          </ImageDiv>
                          <TextComponent $color='rich_black' $fontFamily='var(--font-family-regular)'>
                            <span className='caption-text'>
                              {`
                                ${center?.addressLine1 ? center.addressLine1 : ''}${center?.addressLine2 ? ' ' + center.addressLine2 : ''}${
                                center?.city ? ', ' + center.city : ''
                              }${center?.stateCode ? ', ' + center.stateCode : ''}${center?.zipcode ? ' ' + center.zipcode : ''}
                                `}
                            </span>
                          </TextComponent>
                        </DivWithFlex>
                        <Div $margin='0.8rem 0rem 0rem 0rem' $padding='0rem 0rem 0rem 2.6rem'>
                          <TextComponent $color='titleBlue' $fontFamily='var(--font-family-regular)' $textDecoration='underline'>
                            <span className='caption-text' onClick={() => openMap()}>
                              {t('getDirections')}
                            </span>
                          </TextComponent>
                        </Div>
                      </Div>
                      {/* Center Number */}
                      <DivWithFlex $direction='row' $columnGap='1rem' $margin='2.4rem 0rem 0rem 0rem'>
                        <ImageDiv>
                          <Image src={UIPhoneIcon} alt='phoneIcon' $height='16px' $width='16px' />
                        </ImageDiv>
                        <DivWithFlex $flexWrap='wrap' $columnGap='4rem'>
                          {center && center.telephone && (
                            <TextComponent $color='rich_black' $fontFamily='var(--font-family-regular)'>
                              <a href={`tel:${center?.telephone}`}>
                                <StyledSpan $color='rich_black' $hoverColor='rich_black' className='caption-text'>
                                  {`(${center?.telephone.slice(0, 3)}) ${center?.telephone.slice(3, 6)}-${center?.telephone.slice(6)}`}
                                </StyledSpan>
                              </a>
                            </TextComponent>
                          )}
                          {center && center.isSpanishSupported && (
                            <DivWithFlex $bgColor='bg_light_blue' $padding='0.4rem 0.8rem' $borderRadius='5px' $flexWrap='nowrap'>
                              <SmallTitle $color='bl_blue' $fontFamily='var(--font-family-regular)'>
                                {t('habloEspanol')}
                              </SmallTitle>
                            </DivWithFlex>
                          )}
                        </DivWithFlex>
                      </DivWithFlex>
                    </Div>
                  </DivWithFlex>
                  {/* Appointment Details With Actions */}
                  <Div $margin='2.4rem 0rem 0rem 0rem' $width={isMobileWidth ? '100%' : '50%'}>
                    <Div>
                      <SmallTitle $fontFamily='var(--font-family-regular)' /* 'var(--font-family-regular)' */>
                        {t('appointmentType')}
                      </SmallTitle>
                      <TextComponent $fontFamily='var(--font-family-regular)'>
                        {state?.appointmentType === 'DONATION' ? t('donation') : t('donationWithPhysical') || ''}
                      </TextComponent>
                    </Div>

                    <DivWithFlex $direction='column' $margin='4.8rem 0rem 0rem 0rem' $rowGap='2.4rem'>
                      {isNewDonor && isNewDonor.pdn === null && (
                        <Div $maxWidth={!isMobileWidth ? '37rem' : 'none'}>
                          <PrimaryButton
                            text={t('viewNewDonorChecklist')}
                            variant='solid_blue'
                            sendTo={
                              i18next.language === 'en-US'
                                ? 'https://info.biolifeplasma.com/NewDonorChecklist'
                                : 'https://info.biolifeplasma.com/ES-NDChecklist'
                            }
                            style={{ fontSize: '1.6rem' }}
                            fluid={isMobileWidth}
                          />
                        </Div>
                      )}
                      <Div $maxWidth={!isMobileWidth ? '37rem' : 'none'}>
                        <PrimaryButton
                          text={t('rescheduleOrCancelAppointment')}
                          variant='outline_black'
                          style={{ fontSize: '1.6rem', backgroundColor: 'var(--color-smoke-grey)' }}
                          fluid={isMobileWidth}
                          onClick={() => {
                            toggleModal()
                          }}
                        />
                      </Div>
                      {/* <Div $maxWidth={!isMobileWidth ? '25rem' : 'none'}>
                        <PrimaryButton
                          text={t('modifyPaymentType')}
                          variant='outline_black'
                          style={{ fontSize: '1.6rem' }}
                          fluid={isMobileWidth}
                        />
                      </Div> */}
                    </DivWithFlex>
                  </Div>
                </DivWithFlex>
              </Div>
            </Div>
            <Div className='main-container' $padding='6.4rem 0rem'>
              <DivWithFlex $flexWrap={!isMobileWidth ? 'nowrap' : 'wrap'}>
                <Div $width={isMobileWidth ? '100%' : '50%'}>
                  <AccordianComponent data={center?.hoursOfOperation} />
                </Div>
                <Div
                  //   $margin='6.4rem 0rem 0rem 0rem'
                  $borderRadius='25px'
                  $overflow='hidden'
                  $margin={isMobileWidth ? '6.4rem 0rem 0rem 0rem' : '0rem'}
                  style={{ width: isMobileWidth ? '100%' : '49rem', height: '30rem' }}
                >
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: Config.GOOGLE_MAP_API_KEY }}
                    center={{
                      lat: center?.latitude,
                      lng: center?.longitude
                    }}
                    defaultZoom={14}
                  >
                    <MapMarker key={center?.id} lat={center?.latitude} lng={center?.longitude} text='BioLife Plasma Services' />
                  </GoogleMapReact>
                </Div>
              </DivWithFlex>
            </Div>
          </ExternalComponentSkeleton>

          <Modal
            isOpen={openModal}
            onClose={() => {
              toggleModal()
            }}
            maxWidth={'95vw'}
            maxHeight={'100vh'}
          >
            <AppointmentReschedule
              open={openModal}
              toggleModal={toggleModal}
              appointment={appointment}
              setAppointment={setAppointment}
              handleAppointment={handleAppointment}
              getUpcomingAppointments={getUpcomingAppointments}
              donor={donor}
              apptcenter={apptCenter}
              center={center}
              inquiry={inquiry}
              getZipcodeSearchCenters={getZipcodeSearchCenters}
              getSlotsForAppointmentType={getSlotsForAppointmentType}
              toggleDetailConfirmationModal={toggleDetailConfirmationModal}
              centerSlots={centerSlots}
              centerSlotsLoading={centerSlotsLoading}
              centerSlotsFailure={centerSlotsFailure}
              centers={centers}
              searchedCenters={searchedCenters}
              appointmentDetails={state}
              cancelledClicked={cancelledClicked}
              setCancelledClicked={setCancelledClicked}
              getAppointmentCenter={getAppointmentCenter}
              getAllCenters={getAllCenters}
              appointmentState={state}
            />
          </Modal>
          <Modal
            isOpen={openDetailConfirmationModal}
            onClose={() => {
              toggleDetailConfirmationModal()
            }}
            maxWidth={837}
            maxHeight={'100vh'}
          >
            <AppointmentDetailsConfirmModal
              open={openDetailConfirmationModal}
              toggleModal={toggleDetailConfirmationModal}
              toggleConfirmedAppointmentModal={toggleConfirmedAppointmentModal}
              appointment={appointment}
              update={cancelledClicked ? 'Cancel' : 'Reschedule'}
              donor={donor}
              toggleRescheduleModal={toggleModal}
              rescheduleAppointment={rescheduleAppointment}
              rescheduleAppointmentSuccess={rescheduleAppointmentSuccess}
              rescheduleAppointmentLoading={rescheduleAppointmentLoading}
              rescheduleAppointmentFailure={rescheduleAppointmentFailure}
              toggleConfirmedCancellationModal={toggleConfirmedCancellationModal}
              cancelAppointment={cancelAppointment}
              cancelAppointmentSuccess={cancelAppointmentSuccess}
              cancelAppointmentLoading={cancelAppointmentLoading}
              cancelAppointmentFailure={cancelAppointmentFailure}
              appointmentDetails={state}
              loggedInUser={loggedInUser}
            />
          </Modal>
          <Modal
            isOpen={openConfirmedAppointmentModal}
            onClose={() => {
              toggleConfirmedAppointmentModal()
              navigate(-1)
            }}
            maxWidth={836}
            maxHeight={'100vh'}
          >
            <ConfirmedAppointmentModal
              open={openConfirmedAppointmentModal}
              toggleModal={toggleConfirmedAppointmentModal}
              appointment={appointment}
              donor={donor}
              center={apptCenter}
              update
              createNewAppointmentSuccess={rescheduleAppointmentSuccess}
              createNewAppointmentFailure={rescheduleAppointmentFailure}
              rescheduleAppointmentSuccess={rescheduleAppointmentSuccess}
              loggedInUser={loggedInUser}
              lastPhysicalDate={lastPhysicalDate}
            />
          </Modal>
          <Modal
            isOpen={openConfirmedCancellationModal}
            onClose={() => {
              toggleConfirmedCancellationModal()
              navigate(-1)
            }}
            maxWidth={837}
            maxHeight={'100vh'}
          >
            <ConfirmedCancellationModal
              loggedInUser={loggedInUser}
              open={openConfirmedCancellationModal}
              toggleModal={toggleConfirmedCancellationModal}
              appointment={appointment}
              getSlotsForAppointmentType={getSlotsForAppointmentType}
              center={apptCenter}
              getUpcomingAppointments={getUpcomingAppointments}
              donor={donor}
              cancelAppointmentSuccess={cancelAppointmentSuccess}
              cancelAppointmentLoading={cancelAppointmentLoading}
              cancelAppointmentFailure={cancelAppointmentFailure}
            />
          </Modal>
        </>
      )}
    </>
  )
}

const mapStateToProps = ({ centers, donors, appointments, users }) => ({
  loggedInUser: users.user,
  center: centers.getAllCentersSuccess?.data,
  centerLoading: centers.getAllCentersLoading,
  donorCenters: centers.donorCenters?.data,
  donor: donors.donors?.data,
  appointments: appointments.getUpcomingAppointmentsSuccess,
  appointmentsLoading: appointments.getUpcomingAppointmentsLoading,
  appointmentsError: appointments.getUpcomingAppointmentsFailure,
  inquiry: donors.inquiryDonorSuccess?.data,
  nearbyCenters: centers.nearbyCenters,
  centerStatus: centers.centerStatus,
  rescheduleAppointmentSuccess: appointments.rescheduleAppointmentSuccess?.data,
  rescheduleAppointmentFailure: appointments.rescheduleAppointmentFailure,
  rescheduleAppointmentLoading: appointments.rescheduleAppointmentLoading,
  cancelAppointmentSuccess: appointments.cancelAppointmentSuccess?.data,
  cancelAppointmentLoading: appointments.cancelAppointmentLoading,
  cancelAppointmentFailure: appointments.cancelAppointmentFailure,
  apptCenter: centers.getAppointmentCenterSuccess?.data,
  lastPhysicalDate: donors?.inquiryDonorSuccess?.data?.lastPhysicalDate,
  centerSlots: centers.getSlotsForAppointmentTypeSuccess?.data,
  centerSlotsFailure: centers.getSlotsForAppointmentTypeFailure,
  centerSlotsLoading: centers.getSlotsForAppointmentTypeLoading,
  centers: centers.donorCenters?.data,
  searchedCenters: centers.getZipcodeSearchCentersSuccess?.data,
  isNewDonor: donors?.getDonorsSuccess?.data
})

const mapDispatchToProps = (dispatch) => ({
  getDonorCentersAll: (searchStr) => dispatch(CenterContentActions.getDonorCentersAll(searchStr)),
  getSlotsForAppointmentType: (forDate, fromDateTime, toDateTime, numberOfChildren, centerSfid, appointmentType, rescheduleId, donorSfid) =>
    dispatch(
      CenterContentActions.getSlotsForAppointmentType(
        forDate,
        fromDateTime,
        toDateTime,
        numberOfChildren,
        centerSfid,
        appointmentType,
        rescheduleId,
        donorSfid
      )
    ),
  cancelAppointment: (id, cancelledFrom) => dispatch(AppointmentsActions.cancelAppointment(id, cancelledFrom)),
  getAllCenters: (sfid) => dispatch(CenterContentActions.getAllCenters(sfid)),
  getUpcomingAppointments: (donorSfid) => dispatch(AppointmentsActions.getUpcomingAppointments(donorSfid)),
  getDonorNearbyCenters: (donorSfid) => dispatch(CenterContentActions.getDonorCenterInfo(donorSfid)),
  getAppointmentCenter: (sfid) => dispatch(CenterContentActions.getAppointmentCenter(sfid)),
  getZipcodeSearchCenters: (searchStr, isDIS8Center, searchType, defaultCenter, addNewDonorBonusInformation) =>
    dispatch(CenterContentActions.getZipcodeSearchCenters(searchStr, isDIS8Center, searchType, defaultCenter, addNewDonorBonusInformation)),
  rescheduleAppointment: (id, appointment) => dispatch(AppointmentsActions.rescheduleAppointment(id, appointment))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetails)
