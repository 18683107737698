import React from 'react'
import { Image } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
const LoyaltyAchievementPopUp = importer('Components/LoyaltyAchievement')
const WaysToEarnPopUp = importer('Components/WaysToEarn')
export const getMediaFromDrupalMimeType = (mimeType, link, imageAltText, styles, extraProps) => {
  switch (mimeType) {
    case 'image/png':
    case 'image/svg+xml':
      return <Image src={link} alt={imageAltText} width={styles?.width} height={styles?.height} {...extraProps} />
    case 'image/jpeg':
      return <Image src={link} alt={imageAltText} width={styles?.width} height={styles?.height} {...extraProps} />
    case 'image/webp':
      return <Image src={link} alt={imageAltText} width={styles?.width} height={styles?.height} {...extraProps} />
    default:
      return
  }
}

export const getDrupalUrlTarget = (drupalUrl) => {
  if (drupalUrl?.includes('https') || drupalUrl?.includes('http')) {
    return '_blank'
  } else {
    return '_self'
  }
}

export const getCTAAlignment = (alignment) => {
  switch (alignment) {
    case 1:
      return 'left'
    case 2:
      return 'right'
    case 3:
      return 'top'
    case 4:
      return 'center'
  }
}

//LIGHT BLUE should be #AEE0EA but for demo it's been changed to #EBF7FA
const colorTypes = {
  bl_orange: '#F9A13A',
  bl_blue: '#29398C',
  light_blue: '#EBF7FA',
  yellow: '#FED301',
  green: '#A6CE39',
  orange: '#F96506',
  dark_orange: '#FF4000',
  white: '#FFFFFF',
  rich_black: '#333333',
  dark_gray: '#999999',
  light_gray: '#EDEDED',
  validation_red: '#C70000',
  bg_light_blue: '#EBF7FA',
  orange_gradient: 'linear-gradient(90deg, #EE4823 0%, #F9A13A 100%)',
  orange_gradient_bottom: 'linear-gradient(180deg, #EE4823, #F9A13A)',
  dark_orange_gradient: 'linear-gradient(90deg, #EE4823 70%, #F9A13A 105%)',
  dark_orange_gradient_bottom: 'linear-gradient(180deg, #EE4823 70%, #F9A13A 105%)',
  rich_black_border: '#33333340',
  cardShadow: '#00000029',
  white_smoke: '#F5F5F5',
  blue_underline: '#29398C',
  titleBlue: '#25358E'
}

export const getCssColorFromDrupalColorType = (colorType) => {
  return colorTypes[colorType]
}

export const getLineStylesFromDrupalLineType = (type, isMobile) => {
  switch (type) {
    case 'DOTTED LINE':
      return !isMobile
        ? {
            border: 'none',
            height: '1px',
            backgroundImage: 'linear-gradient(to right, black 15%, rgba(255,255,255,0) 0%)',
            backgroundSize: '8px 1px' /* Adjust the size of each dot and gap */,
            backgroundRepeat: 'repeat-x',
            backgroundPosition: 'left top'
          }
        : {
            border: 'none',
            height: '1px',
            backgroundImage: 'linear-gradient(to right, black 15%, rgba(255,255,255,0) 0%)',
            backgroundSize: '8px 1px' /* Adjust the size of each dot and gap */,
            backgroundRepeat: 'repeat-x',
            backgroundPosition: 'left top'
          }
    default:
      return !isMobile
        ? {
            borderTop: '1px solid #999999',
            height: '0'
          }
        : {
            borderTop: '1px solid #999999',
            height: '0'
          }
  }
}

export const setTextColor = (textColor) => {
  if (textColor?.includes('gradient')) {
    return {
      background: getCssColorFromDrupalColorType(textColor === 'orange_gradient' ? 'orange_gradient_bottom' : textColor),
      backgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    }
  }
  return { color: getCssColorFromDrupalColorType(textColor) }
}

const ComponentMapper = {
  my_achievements: LoyaltyAchievementPopUp,
  ways_to_earn: WaysToEarnPopUp
}
export const renderModalComponent = (type) => {
  const Component = ComponentMapper[type]
  if (Component) {
    return <Component {...type}></Component>
  }
  return null
}
