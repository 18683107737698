import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import chatbotActions from '@takedapdt/biolife-core/src/Stores/Chatbot/Actions'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
import { createBrowserHistory } from 'history'
import { withTranslation } from 'react-i18next'
import { ResponsiveContextProvider } from '../Contexts/ResponsiveContextProvider'
import ContactUsConfirmationRoute from './ContactUsConfirmationRoute'
import Settings from './Settings'
import MessageContainer from './Messages'
import ChooseDonationCenter from './DonationCenterNotSelected'
import Chatbot from '../Components/Chatbot'
import VerifyAccountFromEmail from './VerifyAccountFromEmail'

/* ACTIONS */
const GlobalStyles = importer('styles/GlobalStyles')
const UserActions = importer('Stores/Users/Actions')
const StartupActions = importer('Stores/Startup/Actions')
const AppContainer = importer('Containers/App')
const BiolifeHome = importer('Routes/BiolifeHome')
const AlertModal = importer('Components/AlertModal')
const InactivityTimer = importer('Helpers/InactivityTimer')
const NotFound = importer('Routes/NotFound')
const LogIn = importer('Routes/LogIn')
const LogOut = importer('Routes/LogOut')
const DonorForgotPassword = importer('Routes/DonorForgotPassword')
const AuthResetPassword = importer('Routes/ResetPassword')
const Registration = importer('Routes/Registration')
const SignUpSuccessRoute = importer('Routes/SignUpSuccessRoute')
const ContactUsRoute = importer('Routes/ContactUsRoute')
const CustomPageContainer = importer('Routes/CustomPageContainer')
const NoCenterFound = importer('Routes/NoCenterFound')
const CreateAccount = importer('Routes/CreateAccount')
const LocationsPageContainer = importer('Routes/LocationsPageContainer')
const AuthSignInAuthorize = importer('Routes/SignInAuth')
const CheckEmail = importer('Routes/CheckEmail')
const EmailConfirmation = importer('Routes/EmailConfirmation')
const SocialLogin = importer('Routes/SocialLogin')
const CenterDetailsContainer = importer('Routes/CenterDetailsContainer')
const Preview = importer('Routes/Preview')
const PostLoginPageContainer = importer('Routes/PostLoginPageContainer')
const ShareWithUsContainer = importer('Routes/ShareWithUsContainer')
const ShareWithUsSuccess = importer('Routes/ShareWithUsSuccess')
const MyBalancesContainer = importer('Routes/MyBalancesContainer')
const BlogDetailsContainer = importer('Routes/BlogDetailsContainer')
const AppointmentScheduling = importer('Routes/AppointmentScheduling')
const NewsDetailsContainer = importer('Routes/NewsDetailsContainer')
import TagManager from 'react-gtm-module'
const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 132px);
  opacity: 1 !important;
  position: relative;
  transition: opacity 0.5s;
`

const RouteWrapper = ({ element: Component }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const currentUrl = location.pathname

  useEffect(() => {
    let pathname = location.pathname.toLowerCase()
    let searchParams = new URLSearchParams(location.search)
    const lng = searchParams.get('lng')
    const origin = searchParams.get('origin')
    if (origin) {
      TagManager.dataLayer({
        dataLayer: {
          originFrom: origin
        }
      })
    } 
    // Remove lng param from search
    if (lng) {
      const currentLang = window?.localStorage?.getItem('i18nextLng')
      window?.localStorage?.setItem('originalLng', currentLang)
      searchParams.delete('lng')
    }

    const search = searchParams.toString()

    // Check if pathname ends with a slash or a blank space and remove it.
    if (pathname !== '/') {
      dispatch(chatbotActions.setChatbotForPageView(false, false))
      dispatch(chatbotActions.showHideChatbot(false))
      if (pathname.endsWith('/') || pathname.endsWith(' ')) {
        navigate({ pathname: currentUrl.slice(0, -1), search }, { replace: true })
      } else if (pathname.endsWith('%20')) {
        navigate({ pathname: currentUrl.slice(0, -3), search }, { replace: true })
      }
    }

    // Check if pathname has changed, lng param exists or pathname contains ',-' and redirect
    if (pathname !== location.pathname || lng || (pathname.includes('locations') && pathname.includes(',-'))) {
      const updatedPathname = pathname.includes('locations') && pathname.includes(',-') ? pathname.replace(/,-[^/]*$/, '') : pathname
      navigate({ pathname: updatedPathname, search }, { replace: true })
    }
  }, [location, navigate])

  return <Component />
}

const App = ({ loggedInUser, loggedInSession, initializeApp, getLoggedInUser, sessionRefresh, logout, t, i18n }) => {
  // chatbotMinimized used For chatbot Inactivity handeling
  const { innerWidth } = window
  const chatbotWindow = useRef(null)
  const isChatbotopen = useRef()
  const showHideChatbot = useSelector((state) => state.chatbot.showChatbot)
  const [outsideChatbot, setOutsideChatbot] = useState(false)
  const [chatbotMinimized, setChatbotMinized] = useState(false)
  const [showInactivityPopup, setShowInactivityPopup] = useState(0)
  const [inactiveAlertOpen, setInactiveAlertOpen] = useState(false)
  const [isAuthenticatedUser, setIsAuthenticatedUser] = useState()
  const history = createBrowserHistory()
  let bodyObserver = null
  const handleClickedOutsideChatbotEvent = (e) => {
    // Working only on mobile. 767 is max mobile width
    if (innerWidth <= 767 && isChatbotopen.current && chatbotWindow.current && !chatbotWindow.current?.contains(e.target)) {
      setOutsideChatbot(true)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickedOutsideChatbotEvent)
    getLoggedInUser()
    // TO-DO: Try to remove this initializeApp call as its calling all APIs multiple times.
    initializeApp()
    const isAuthenticated = isDonorLoggedIn()
    if (isAuthenticated) {
      const timer = 25
      InactivityTimer(timer, () => {
        setInactiveAlertOpen(true)
      })
    }
    setIsAuthenticatedUser(isAuthenticated && loggedInUser)
    window.localStorage.setItem('showAlert', true)
    window.localStorage.setItem('showSmartBanner', true)
    window.localStorage.setItem('openAppModal', true)

    // Start observing changes within the entire body
    bodyObserver = new MutationObserver(handleOTBannerAdded.bind(this))
    bodyObserver.observe(document.body, { childList: true, subtree: true })
    return () => {
      console.log('observed disconneced')
      if (bodyObserver) {
        bodyObserver.disconnect()
      }
    }
  }, [])

  const handleIsChatbotOpen = (value) => {
    isChatbotopen.current = value
  }

  const handleOutsideChatbot = (value) => {
    setOutsideChatbot(value)
  }

  // This is a callback function that will be invoked when mutations are observed
  function handleOTBannerAdded(mutationsList) {
    for (const mutation of mutationsList) {
      if (mutation.addedNodes.length > 0) {
        const oneTrustComponent = document.getElementById('onetrust-consent-sdk')
        if (oneTrustComponent) {
          const allConsentToggles = document.querySelectorAll('#onetrust-consent-sdk .category-switch-handler')
          const consentConfirmBtn = document.querySelector('#onetrust-consent-sdk .onetrust-close-btn-handler')
          // Variable to track the previous checkbox status
          let previousCheckboxStatus = Array.from(allConsentToggles, (checkbox) => checkbox?.checked)
          consentConfirmBtn?.addEventListener('click', () => {
            let hasUncheckedChange = false
            // Check the status of all checkboxes and compare with the previous status
            allConsentToggles?.forEach(function(checkbox, index) {
              const currentStatus = checkbox?.checked
              if (currentStatus !== previousCheckboxStatus[index]) {
                hasUncheckedChange = true
              }
              previousCheckboxStatus[index] = currentStatus // Update previous status
            })
            if (hasUncheckedChange) {
              console.log('User has removed the consent for some categories')
              //reload page when consent is changed
              window.location.reload()
            }
          })
          // Stop observing once the component is found
          bodyObserver.disconnect()
          return
        }
      }
    }
  }

  const isDonorLoggedIn = () => {
    // alert(history.location.pathname + '  --- logged in data --- ' + JSON.stringify(loggedInUser))
    return loggedInUser && loggedInSession && loggedInUser.loginId
  }

  // const isAdminLoggedIn = () => {
  //   return loggedInUser && loggedInSession && loggedInUser.role === 'Admin'
  // }

  // const isCenterManager = () => {
  //   return loggedInUser && loggedInSession && loggedInUser.systemRole === 'Manager'
  // }
  const alertModal = () => {
    return (
      <AlertModal
        open={inactiveAlertOpen}
        title={t('youAreAboutToSignOut')}
        message={t('forSecurityReasons')}
        timedAction={{
          timer: 4 * 60 * 1000,
          callback: () =>
            setInactiveAlertOpen(false, () => {
              window.sessionStorage.setItem('logoutTimeOut', true)
              logout()
            })
        }}
        actions={[
          {
            text: t('continue'),
            primary: true,
            callback: () => setInactiveAlertOpen(false, () => sessionRefresh())
          }
        ]}
      />
    )
  }
  const RoutePrivate = (children) => {
    return isAuthenticated ? children : <Navigate to='/login' replace />
  }

  const handleChatbotMinized = (value) => {
    setChatbotMinized(value)
  }

  const isAuthenticated = isDonorLoggedIn()
  return (
    <Router history={history}>
      <GlobalStyles />
      <ResponsiveContextProvider>
        <AppContainer compact logged={isAuthenticatedUser}>
          <AppWrapper logged={isAuthenticated}>
            <Helmet
              defer={false}
              htmlAttributes={{ lang: i18n.language }}
              encodeSpecialCharacters
              defaultTitle={process.env.APP_NAME}
              titleAttributes={{ itemprop: 'name', lang: i18n.language }}
            />
            <Chatbot
              ref={chatbotWindow}
              isAuthenticatedUser={isAuthenticatedUser}
              handleIsChatbotOpen={handleIsChatbotOpen}
              outsideChatbot={outsideChatbot}
              handleOutsideChatbot={handleOutsideChatbot}
              showInactivityPopup={showInactivityPopup}
              handleInactivityPopup={(value) => {
                setShowInactivityPopup(value)
              }}
              handleChatbotMinized={handleChatbotMinized}
              chatbotMinimized={chatbotMinimized}
            />
            {alertModal()}
            {/* All the RoutePublic route components should not have the isAuthenticated prop.
                isAuthenticated prop should only be passed on to RoutePrivate route components. */}
            <Routes>
              <Route path='/' element={<RouteWrapper element={BiolifeHome} />} />
              <Route path='/preview' element={<RouteWrapper element={Preview} />} />
              <Route path='/authorize' element={<RouteWrapper element={AuthSignInAuthorize} />} />
              <Route path='/no-center-zone' element={<RouteWrapper element={NoCenterFound} />} />
              <Route path='/email-confirmation' element={<RouteWrapper element={EmailConfirmation} />} />
              <Route path='/logout' element={<RouteWrapper element={LogOut} />} />
              <Route path='/login' element={<RouteWrapper element={LogIn} />} />
              <Route path='/check-email' element={<RouteWrapper element={CheckEmail} />} />
              <Route path='/donor-forgot-password' element={<RouteWrapper element={DonorForgotPassword} />} />
              <Route path='/reset-password' element={<RouteWrapper element={AuthResetPassword} />} />
              <Route path='/verify-account-from-email' element={<RouteWrapper element={VerifyAccountFromEmail} />} />
              <Route path='/registration' element={<RouteWrapper element={Registration} />} />
              <Route path='/verify-email' element={<RouteWrapper element={SignUpSuccessRoute} />} />
              <Route path='/create-account' element={<RouteWrapper element={CreateAccount} />} />
              <Route path='/social-login' element={<RouteWrapper element={SocialLogin} />} />
              <Route path='/account-setup-success' element={<RouteWrapper element={CheckEmail} />} />
              <Route path='/about-biolife/contact-us' element={<RouteWrapper element={ContactUsRoute} />} />
              <Route path='/contact-us-confirmation' element={<RouteWrapper element={ContactUsConfirmationRoute} />} />
              <Route path='/locations' element={<RouteWrapper element={LocationsPageContainer} />} />
              <Route path='/locations/:locationSlug' element={<RouteWrapper element={LocationsPageContainer} />} />
              <Route path='/locations/:locationSlug/:centerSlug' element={<RouteWrapper element={CenterDetailsContainer} />} />
              <Route path='/share-with-us' element={<RouteWrapper element={ShareWithUsContainer} />} />
              <Route path='/share-with-us-success' element={<RouteWrapper element={ShareWithUsSuccess} />} />
              <Route path='/blog/:blogDetails' element={<RouteWrapper element={BlogDetailsContainer} />} />
              <Route path='/about-biolife/news/:newsDetails' element={<RouteWrapper element={NewsDetailsContainer} />} />
              {/* Private Routes */}
              <Route path='/dashboard/:page/:segment?' element={RoutePrivate(<RouteWrapper element={PostLoginPageContainer} />)} />
              <Route path='/dashboard/my-balances/:tabsSlug?' element={RoutePrivate(<RouteWrapper element={MyBalancesContainer} />)} />
              <Route path='/dashboard/messages' element={RoutePrivate(<RouteWrapper element={MessageContainer} />)} />
              <Route path='/appointment-scheduling' element={RoutePrivate(<RouteWrapper element={AppointmentScheduling} />)} />
              <Route path='/dashboard/settings' element={RoutePrivate(<RouteWrapper element={Settings} />)} />
              <Route path='/dashboard/choose-donation-center' element={RoutePrivate(<RouteWrapper element={ChooseDonationCenter} />)} />
              <Route path='*' element={<RouteWrapper element={CustomPageContainer} />} />
            </Routes>
          </AppWrapper>
        </AppContainer>
      </ResponsiveContextProvider>
    </Router>
  )
}

const mapStateToProps = (state) => ({
  loggedInUser: state.users.user,
  loggedInSession: state.users.session
})

const mapDispatchToProps = (dispatch) => ({
  initializeApp: () => dispatch(StartupActions.initializeApp()),
  getLoggedInUser: () => dispatch(UserActions.getLoggedInUser()),
  sessionRefresh: () => dispatch(UserActions.sessionRefresh()),
  logoutTimeout: () => dispatch(UserActions.logoutTimeout()),
  logout: () => dispatch(UserActions.logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('Index')(App))
