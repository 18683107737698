import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Grid, Modal } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import { numberWithCommasWithOutDecimals } from '../../Helpers/Utils'
import { ReactComponent as CircleError } from '../../../assets/media/icons/UI-circle-error.svg'
import { ReactComponent as RedemptionSuccessIcon } from '../../../assets/media/icons/sign-up-icon-ui-success.svg'
import { ReactComponent as ErrorIcon } from '../../../assets/media/icons/sign-up-icon-ui-failure.svg'
import { ReactComponent as IncrementIcon } from '../../../assets/media/icons/UI-add-icon.svg'
import { ReactComponent as DecrementIcon } from '../../../assets/media/icons/UI-reduce-icon.svg'
import { ReactComponent as IncrementDisabledIcon } from '../../../assets/media/icons/UI-add-icon-disabled.svg'
import { ReactComponent as DecrementDisabledIcon } from '../../../assets/media/icons/UI-reduce-icon-disabled-reduce.svg'
/* 
components
*/
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType, setTextColor, getMediaFromDrupalMimeType } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const LoyalDonorActions = importer('Stores/Loyalty/Actions')
const PublicContentActions = importer('Stores/PublicContent/Actions')
const CustomModal = importer('Components/CustomModal')
const { renderModalComponent } = importer('Constant/Utils', null)
const LoyaltyModal = importer('Components/Modal')
const LoyaltyEnrolmentModal = importer('Components/Modal/LoyaltyEnrolmentModal')
const LoyaltyEnrolmentSuccessModal = importer('Components/Modal/LoyaltyEnrolmentSuccessModal')

const BannerContainer = styled.div`
  background: var(--color-white);
  justify-content: space-between;
  align-items: center;
`
const TextSection = styled.div`
  width: 50%;
`
const Title = styled.h1`
  font-size: var(--h4-font-size);
  padding-bottom: 1.6rem;
`
const Subtitle = styled.h2`
  font-size: var(--h5-font-size);
  padding-bottom: 3.2rem;
  font-weight: normal;
`
const Description = styled.p`
  letter-spacing: 0;
  padding-bottom: 4rem;
`
const EnrollButton = styled.button`
  color: var(--color-white);
  cursor: pointer;
  text-transform: uppercase;
  background-color: var(--color-white);
  border: none;
`
const ImageContainer = styled.div`
  width: 50%;
  & img {
    width: 100%;
    height: 100%;
  }
`
const TextSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2.4rem;
  ${(props) => {
    if (props.$mediaAlignment === 1) {
      return `
                flex-direction: row-reverse;
            `
    }
  }};
`
const RewardsContainer = styled.div`
  background: var(--color-white);
  padding: 1.6rem;
  box-shadow: 0 0.4rem 0.8rem var(--color-box-shadow);
  border-radius: 1rem;
  height: 8.7rem;
`
const PointsHeader = styled.div`
  color: var(--color-bl-blue);
  font-size: 1.4rem !important;
  text-align: left;
  font-family: var(--font-family-medium) !important;
`
const PointsValue = styled.p`
  font-size: var(--h3-font-size) !important;
  color: var(--color-light-orange);
  margin-top: 1rem !important;
`
const ExpirationNotice = styled.div`
  color: var(--color-medium-grey);
  font-size: 0.8em;
  padding-left: 0.8rem;
  margin-top: -0.2rem;
`
const PointsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const BannerCurve = styled.div`
  ${devices.tablet} {
    border-radius: 0rem 0rem 4.8rem 4.8rem;
  }
  border-radius: 0 0 20rem 20rem;
  ${devices.mobile} {
    border-radius: 0 0 4.8rem 4.8rem;
  }
  padding-bottom: 50px;
`
const RewardsCard = styled.div`
  ${devices.mobile} {
    width: 100%;
  }
  width: 50%;
`
const Container = styled.div``
const Header = styled.div`
  padding: 1.6rem;
  text-align: center;
`
const Body = styled.div``
const ImageWrapper = styled.div`
  position: relative;
  text-align: center;
  padding-top: 3.2rem;
`
const AvailablePointsContainer = styled.div`
  padding: 0 2.4rem;
`
const BodyText = styled.p`
  margin-bottom: 20px;
  padding: 2.4rem 2.4rem 0 2.4rem;
`
const Loyaltypoints = styled.div``
const MobileImage = styled.div`
  & img {
    width: 100%;
  }
  display: flex;
  justify-content: center;
`
const ExpirationBox = styled.div`
  display: flex;
  padding-top: 2.4rem;
`
const Button = styled.button`
  background-color: var(--color-white);
  color: black;
  padding: 0 2.4rem;
  padding-top: 2rem;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  text-transform: uppercase;
  font-weight: bold;
`
const IncrementButton = styled.button`
  background: transparent;
  border: none;
  font-size: var(--h5-font-size);
  cursor: pointer;
  user-select: none;
  & svg {
    width: 3.2rem;
    height: 3.2rem;
  }
`
const DecrementButton = styled.button`
  background: transparent;
  border: none;
  font-size: var(--h5-font-size);
  cursor: pointer;
  user-select: none;
  & svg {
    width: 3.2rem;
    height: 3.2rem;
  }
`
const Title1 = styled.h2`
  font-size: var(--p-font-size);
  text-align: center;
`
const SegmentControl = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  column-gap: 0.8rem;
  border: 0 solid #ccc;
`
const SegmentButton = styled.button`
  background: transparent;
  background-color: ${(props) => (props.active ? '#F96507' : 'initial')};
  color: ${(props) => (props.active ? 'white' : 'black')};
  border: ${(props) => (props.active ? '0px solid #ccc' : '1px solid #ccc')};
  padding: 0.8rem 1.6rem;
  border-radius: 2rem;
  cursor: pointer;
  font-size: 1.4rem;

  &.active {
    background-color: var(--color-light-orange);
    color: var(--color-white);
  }
`
const CalculatorSection = styled.section`
  margin-bottom: 2rem;
`
const PointsInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  padding-top: 1.6rem;
`
const ButtonText = styled.p`
  font-size: 1.4rem !important;
  letter-spacing: 0 !important;
  padding-bottom: 3rem;
  border-bottom: 0.1rem solid var(--color-light-shade-gray);
  text-align: center;
  line-height: 2.1rem !important;
`
const AvailablePoints = styled.p`
  font-size: 1.4rem !important;
  letter-spacing: 0 !important;
`
const CashValueDisplay = styled.div`
  background-color: var(--color-primary-white);
  padding: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  border-radius: 1.4rem;
`
const ContainerOne = styled.div`
  ${devices.mobile} {
    padding: 0;
  }
  background: var(--color-white);
  border-radius: 0.8rem;
  padding-top: 0 !important;
  padding: 2rem;
  max-width: 40rem;
  margin: 0 auto;
`
const RedeemHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 2.8rem;
`
const RedeemHeader = styled.p`
  background: var(--color-medium-shade-gray) 0% 0% no-repeat padding-box;
  border-radius: 0.4rem;
  font-size: 1.4rem !important;
  padding: 0.4rem 0.8rem;
`
const Label = styled.label`
  display: block;
  font-size: var(--caption-mobile-font-size);
  margin-bottom: 0.5rem;
`
const SuccessModalContainer = styled.div`
  background: var(--color-white);

  margin: 0 auto;
`
const ErrorModalContainer = styled.div`
  background: var(--color-white);

  width: auto;
`
const PointsCalculator = styled.p`
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  text-align: center;
  font-size: var(--h5-font-size);
  color: var(--color-medium-blue);
`
const SuccessIcon = styled.div`
  text-align: center;
  font-size: var(--h4-font-size);
  color: green;
  & svg {
    width: 6rem;
    height: 6rem;
  }
`
const SuccessTitle = styled.h2`
  color: var(--color-medium-blue);
  text-align: center;
  margin-bottom: 4.8rem;
  font-size: var(--h4-font-size) !important;
`
const Amount = styled.div`
  color: var(--color-light-orange);
  font-size: var(--h1-font-size);
  text-align: center;
  margin: 0.8rem 0;
`
const PointsCount = styled.div`
  display: flex;
  border: 1px solid var(--color-dark-gray);
  border-radius: 1rem;
  justify-content: space-between;
  padding: 1.4rem 1.2rem;
  column-gap: 4.8rem;
  ${devices.mobile} {
    column-gap: 0;
  }
`
const CashCount = styled.p`
  font-size: 4.4rem !important;
  margin-top: 3rem !important;
`
const Message = styled.p`
  text-align: center;
  font-size: 1.4rem;
`
const MessageText = styled.p`
  text-align: center;
  margin-bottom: 4.8rem;
  font-size: 1.4rem;
`
const PointsInfo = styled.p`
  text-align: center;
  color: var(--color-dark-grey);
  margin-bottom: 18.7rem;
  font-size: var(--caption-mobile-font-size) !important;
`
const ErrorInfo = styled.p`
  ${devices.mobile} {
    padding: 0;
  }
  text-align: center;
  color: var(--color-rich-black);
  padding-left: 5.2rem;
  padding-right: 5.2rem;
  margin-bottom: 18.7rem;
  font-size: var(--caption-font-size) !important;
`
const ButtonContainer = styled.div`
  ${devices.mobile} {
    padding: 0;
  }
  display: flex;
  column-gap: 2.4rem;
`
const RedeemButtonSuccess = styled.button`
  background-color: var(--color-white);
  color: var(--color-white);
  border: none;
  padding: 1.5rem 0 3rem;
  border-radius: 0 3rem;
  font-size: var(--caption-font-size);
  width: 100%;
  cursor: pointer;
`
const RedeemButton = styled.button`
  text-align: center;
  background-color: var(--color-white);
  color: var(--color-white);
  border: none;
  padding: 0 3rem;
  border-radius: 3rem;
  font-size: var(--caption-font-size);
  width: 100%;
  cursor: pointer;
`
const RedeemPausedContainer = styled.div`
  background: var(--color-white);
  padding-top: 1rem;
  border-radius: 1.2rem;
  margin: 0 auto;
  text-align: left;
`
const ErrorTitle = styled.h3`
  color: var(--color-bl-blue);
  text-align: left;
  margin-bottom: 1.6rem;
  font-size: var(--h3-font-size);
`

const ErrorMessage = styled.p`
  text-align: justify;
  margin-bottom: 3.2em;
`
const NavigateWaysToEarn = styled.a`
  color: var(--color-rich-black);
  text-decoration: underline;
`
const ImageLogo = styled.div`
  display: flex;
  justify-content: center;
  & img {
    padding: 24px;
  }
`
const RedeemOffError = styled.p`
  padding-bottom: 20px;
  color: var(--color-validation-red);
`

const RedemptionDescription = ({ redeemCondition, textMessage, textErrorMessage }) => {
  if (redeemCondition) {
    return <ButtonText>{textMessage}</ButtonText>
  } else {
    return <RedeemOffError>{textErrorMessage}</RedeemOffError>
  }
}
const VendorLink = ({ link, text }) => {
  return <a href={link} target='_blank' className='underline-dark-blue'>{text}</a>
}

const BiolifeRewards = ({
  getLoyaltyDetailsByPdn,
  loyaltyActivity,
  loyaltyPoints,
  donor,
  uniqueData,
  getLoyaltyConstant,
  redeemRewards,
  redeemConstantValues,
  loyaltyDetails,
  getLoyaltyConstantLoading,
  redeemRewardsLoading,
  redeemRewardsFailure,
  redeemRewardsResponse,
  redeemRewardsStatusCheck,
  redeemRewardsStatusCheckLoading,
  redeemRewardsStatusCheckFailure,
  redeemRewardsStatusCheckResponse,
  user,
  redeemPaused,
  vendorRedeemPaused,
  getRedeemPaused,
  getVendorRedeemPaused,
  PostLoginPageData
}) => {
  const { t, i18n } = useTranslation('RedeemRewards')
  const { isMobileWidth } = useScreenSize()
  const [openRedeemModal, setOpenRedeemModal] = useState(false)
  const [openRedeemSuccessModal, setOpenRedeemSuccessModal] = useState(false)
  const [openRedeemFailureModal, setOpenRedeemFailureModal] = useState(false)
  const [openNotEnoughPointsModal, setOpenNotEnoughPointsModal] = useState(false)
  const [openRedeemPausedModal, setOpenRedeemPausedModal] = useState(false)
  const [redeemOptions, setRedeemOptions] = useState('DEBIT_CARD')
  const [redeemConstants, setRedeemConstants] = useState({})
  const [redeemPoints, setredeemPoints] = useState(0)
  const [cash, setCash] = useState(5)
  const [loyaltyMinPoints, setLoyaltyMinPoints] = useState(0)
  const [redeemSubmitted, setRedeemSubmitted] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [data, setData] = useState(null)
  const [isLoyaltyEnrolmentModalOpen, setIsLoyaltyEnrolmentModalOpen] = useState(false)
  const [isLoyaltyEnrolmentSuccessModalOpen, setIsLoyaltyEnrolmentSuccessModalOpen] = useState(false)
  const [isLoyaltyEnrolmentModalSubmitClicked, setIsLoyaltyEnrolmentModalSubmitClicked] = useState(false)
  const [loyaltyEnrolmentSuccess, setLoyaltyEnrolmentSuccess] = useState(false)
  const [isDebitCardRedeemOff, setIsDebitCardRedeemOff] = useState(false)
  const [isGiftCardRedeemOff, setIsGiftCardRedeemOff] = useState(false)
  const [isCharityRedeemOff, setIsCharityRedeemOff] = useState(false)
  const navigate = useNavigate()
  const handleOpen = (type) => {
    setData(PostLoginPageData.find((item) => item.field_poup_type === type))
    setOpenModal(true)
  }

  useEffect(() => {
    if (vendorRedeemPaused) {
      vendorRedeemPaused?.map((item) => {
        if (item.bll_redemption_type__c === 'Debit Card' && item.bll_active__c === 'No') {
          setIsDebitCardRedeemOff(true)
        }
        if (item.bll_redemption_type__c === 'Gift Card' && item.bll_active__c === 'No') {
          setIsGiftCardRedeemOff(true)
        }
        if (item.bll_redemption_type__c === 'Charitable Donation' && item.bll_active__c === 'No') {
          setIsCharityRedeemOff(true)
        }
      })
    }
  }, [vendorRedeemPaused])

  const redeemPointsFormSubmit = () => {
    const data = {
      loyaltyPoints: redeemPoints,
      loyaltyAmount: cash,
      redeemOption: redeemOptions,
      pdn: user.pdn
    }

    redeemRewards(data)
    setRedeemSubmitted(true)
  }

  const [redeemCheckTrials, setRedeemCheckTrials] = useState(0)

  /* 
  checking for if donor type is existing member and loyalty enrolled donor we will available points card 
*/
  useEffect(() => {
    if (donor?.isLoyaltyMember === 'Yes') {
      if (donor?.pdn != null) {
        getLoyaltyDetailsByPdn(donor?.pdn)
      }
    }
  }, [donor])
  useEffect(() => {
    //Converting string into numbers
    if (redeemConstantValues !== null) {
      setRedeemConstants({
        LOYALTY_CHARITY_RATE_N: Number(redeemConstantValues?.LOYALTY_CHARITY_RATE),
        LOYALTY_DEBIT_CARD_RATE_N: Number(redeemConstantValues?.LOYALTY_DEBIT_CARD_RATE),
        LOYALTY_GIFT_CARD_RATE_N: Number(redeemConstantValues?.LOYALTY_GIFT_CARD_RATE),
        LOYALTY_INCREMENTAL_POINTS_N: Number(redeemConstantValues?.LOYALTY_INCREMENTAL_POINTS),
        LOYALTY_MAXIMUM_REDEEM_POINTS_N: Number(redeemConstantValues?.LOYALTY_MAXIMUM_REDEEM_POINTS),
        LOYALTY_MINIMUM_REDEEM_POINTS_N: Number(redeemConstantValues?.LOYALTY_MINIMUM_REDEEM_POINTS)
      })
      // Set inital value for Redeem points as minimum redeem points
      setredeemPoints(Number(redeemConstantValues?.LOYALTY_MINIMUM_REDEEM_POINTS))
      setLoyaltyMinPoints(Number(redeemConstantValues?.LOYALTY_MINIMUM_REDEEM_POINTS))
    }
  }, [redeemConstantValues])
  // handle sucess
  useEffect(() => {
    if (redeemSubmitted && redeemRewardsResponse !== null) {
      setOpenRedeemModal(false)
      setOpenRedeemSuccessModal(true)
    }
  }, [redeemRewardsResponse])
  // handle failure
  useEffect(() => {
    if (redeemSubmitted === true && redeemRewardsFailure !== null) {
      if (redeemRewardsFailure.code == 'ERR_NETWORK'  && redeemRewardsFailure.message == 'Network Error' && !redeemRewardsFailure.response && redeemCheckTrials <= 8) {
        const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
        sleep(5000)
        setRedeemCheckTrials(redeemCheckTrials + 1)

        redeemRewardsStatusCheck(user.pdn)
      } else {
        setOpenRedeemModal(false)
        setOpenRedeemFailureModal(true)
        setRedeemCheckTrials(0)
      }
    }
  }, [redeemRewardsFailure])

  useEffect(() => {
    if (redeemSubmitted === true && redeemRewardsStatusCheckFailure !== null) {
      if (redeemRewardsFailure.code == 'ERR_NETWORK' && redeemRewardsFailure.message == 'Network Error' && !redeemRewardsFailure.response && redeemCheckTrials <= 8) {
        const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
        sleep(5000)
        setRedeemCheckTrials(redeemCheckTrials + 1)

        redeemRewardsStatusCheck(user.pdn)
      } else {
        setOpenRedeemModal(false)
        setOpenRedeemFailureModal(true)
        setRedeemCheckTrials(0)
      }
    }
  }, [redeemRewardsStatusCheckFailure])

  useEffect(() => {
    if (redeemSubmitted === true && redeemRewardsStatusCheckResponse !== null) {
      setOpenRedeemModal(false)
      setOpenRedeemSuccessModal(true)
      setRedeemCheckTrials(0)
    }
  }, [redeemRewardsStatusCheckResponse])

  const incrementRedeemPonts = () => {
    if (
      redeemPoints + redeemConstants?.LOYALTY_INCREMENTAL_POINTS_N <= loyaltyPoints &&
      redeemPoints <= redeemConstants?.LOYALTY_MAXIMUM_REDEEM_POINTS_N
    ) {
      setredeemPoints(redeemPoints + redeemConstants.LOYALTY_INCREMENTAL_POINTS_N)
    }
  }
  const decrementRedeemPonts = () => {
    if (redeemPoints > redeemConstants?.LOYALTY_MINIMUM_REDEEM_POINTS_N) {
      setredeemPoints(redeemPoints - redeemConstants?.LOYALTY_INCREMENTAL_POINTS_N)
    }
  }
  // Get Loyalty contants on the inital render
  useEffect(() => {
    getLoyaltyConstant()
    getVendorRedeemPaused()
  }, [])
  const handleSegmentClick = (option) => {
    setActiveOption(option)
    if (option === 'Debit Card') {
      setCalculatorTitle('Cha-Ching! Add more money to your BioLife Debit Card in exchange for points.')
    } else if (option === 'Gift Card') {
      setCalculatorTitle('Select your favorite Gift Card to exchange for points.')
    } else if (option === 'Charity') {
      setCalculatorTitle('Donate your points to a charity of your choice.')
    }
  }
  useEffect(() => {
    switch (redeemOptions) {
      case 'DEBIT_CARD':
        setCash(redeemPoints * redeemConstants?.LOYALTY_DEBIT_CARD_RATE_N)
        break
      case 'GIFT_CARD':
        setCash(redeemPoints * redeemConstants?.LOYALTY_GIFT_CARD_RATE_N)
        break
      case 'CHARITY':
        setCash(redeemPoints * redeemConstants?.LOYALTY_CHARITY_RATE_N)
        break
    }
  }, [redeemPoints, redeemOptions])
  useEffect(() => {
    getRedeemPaused()
  }, [])
  /* 
  checking for redeempaused for all vendors and if true we will show redeempaused pop up 
  if not based on loyalty points availibility we will redemption pop up 
  if loyalty points are not sufficient then we will show need more points pop up modal 
*/
  const redeemPausedMethod = () => {
    if (redeemPaused?.bll_redeem_paused__c === true) {
      setOpenRedeemPausedModal(true)
    } else if (
      loyaltyPoints === null ||
      loyaltyPoints === undefined ||
      isEmpty(user?.pdn) ||
      loyaltyPoints < redeemConstants?.LOYALTY_MINIMUM_REDEEM_POINTS_N
    ) {
      setOpenNotEnoughPointsModal(true)
    } else {
      setOpenRedeemModal(true)
    }
  }
  /* 
  checking for if donor type is existing member and loyalty enrolled donor we will available points card 
*/
  useEffect(() => {
    if (donor?.isLoyaltyMember === 'Yes') {
      if (donor?.pdn != null) {
        getLoyaltyDetailsByPdn(donor.pdn)
      }
    }
  }, [i18n.language])
  /* 
  Updating redemption pop to its initial value
*/
  useEffect(() => {
    if (openRedeemModal) {
      setRedeemOptions('DEBIT_CARD')
      setCash(5)
      setredeemPoints(1250)
    }
  }, [openRedeemModal])
  /* 
  donor who is not enrolled in the loyalty program wanted to enroll, 
  and upon clicking the button, showing loyalty enrollment success modal
*/
  useEffect(() => {
    if (isLoyaltyEnrolmentModalSubmitClicked) {
      setLoyaltyEnrolmentSuccess(true)
      setIsLoyaltyEnrolmentSuccessModalOpen(true)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [isLoyaltyEnrolmentModalSubmitClicked])
  const isLoyaltyEnabled = donor?.defaultCenter?.isLoyaltyProgramEnabled || false
  const handleLoyaltyEnrolmentButtonClick = () => {
    setIsLoyaltyEnrolmentModalOpen(true)
  }
  /* 
  This function displays available reward points data and shows an expiration message if the points are expiring.
*/
  const RedeemRewardsRender = ({ loyaltyPoints, loyaltyActivity }) => {
    return (
      <RewardsCard>
        <RewardsContainer>
          <PointsContainer>
            <PointsHeader>{t('availablerewards')}</PointsHeader>
            <PointsValue>
              <strong>{numberWithCommasWithOutDecimals(loyaltyPoints) || 0}</strong>
            </PointsValue>
          </PointsContainer>
        </RewardsContainer>
        {loyaltyActivity?.expirationDetails?.expirationMsg ? (
          <ExpirationBox>
            <CircleError />
            <ExpirationNotice>
              {i18n.language === 'es-MX'
                ? loyaltyActivity?.expirationDetails?.expirationMsgSpanish
                : loyaltyActivity?.expirationDetails?.expirationMsg}
            </ExpirationNotice>
          </ExpirationBox>
        ) : null}
      </RewardsCard>
    )
  }
  /* 
  This function displays a button based on the donor type, and if the donor is not enrolled, it adds a loyalty enrollment modal.
*/
  const RenderPrimaryButton = ({ data, donor, isMobileWidth, handleLoyaltyEnrolmentButtonClick, redeemPausedMethod }) => {
    if (data?.field_donor_type === 'existing_member' && donor?.isLoyaltyMember === 'Yes' && donor?.pdn) {
      return (
        <PrimaryButton
          text={data?.field_cta_1?.title}
          variant={data?.field_cta_1_style}
          onClick={redeemPausedMethod}
          fluid={isMobileWidth}
        />
      )
    } else if (
      (data.field_donor_type === 'not_enrolled' && donor?.isLoyaltyMember === 'No') ||
      donor?.isLoyaltyMember === null ||
      donor?.isLoyaltyMember === null
    ) {
      return (
        <PrimaryButton
          text={data?.field_cta_1?.title}
          variant={data?.field_cta_1_style}
          onClick={data?.field_cta_1?.uri?.includes('internal:#modal') ? () => handleLoyaltyEnrolmentButtonClick() : null}
          sendTo={!data?.field_cta_1?.uri?.includes('internal:#modal') ? data?.field_cta_1?.uri : null}
          fluid={isMobileWidth}
        />
      )
    } else {
      return (
        <PrimaryButton
          text={data?.field_cta_1?.title}
          variant={data?.field_cta_1_style}
          sendTo={data?.field_cta_1?.uri}
          fluid={isMobileWidth}
        />
      )
    }
  }
  const RedemptionMessage =
    redeemOptions === 'DEBIT_CARD' ? t('DebitCardText') : redeemOptions === 'GIFT_CARD' ? <>{t('GiftCardText')}<VendorLink link={`mailto:${t('HelpPrezzee')}`} text={t('HelpPrezzee')}/>{t('Visit')}<VendorLink link={t('PrezzeeContact')} text={t('PrezzeeContact')}/></>: t('CharityCardText')
  const RedeemCondition =
    redeemOptions === 'DEBIT_CARD' ? isDebitCardRedeemOff : redeemOptions === 'GIFT_CARD' ? isGiftCardRedeemOff : isCharityRedeemOff
  const RedeemMessage = redeemOptions === 'DEBIT_CARD' ? t('DebitText') : redeemOptions === 'GIFT_CARD' ? t('GiftText') : t('CharityText')
  const RedeemErrorMessage =
    redeemOptions === 'DEBIT_CARD'
      ? t('debitCardOffErrorMessage')
      : redeemOptions === 'GIFT_CARD'
      ? t('giftCardOffErrorMessage')
      : t('charityOffErrorMessage')

  const ScheduleNow = () => {
    setOpenNotEnoughPointsModal(false)
    setOpenRedeemPausedModal(false)
    navigate('/appointment-scheduling')
  }
  return (
    <>
      <div>
        {uniqueData.map((data, index) =>
          (data?.field_donor_type === 'not_yet_donated' &&
            !donor?.pdn &&
            donor?.isLoyaltyMember === 'Yes' &&
            (isLoyaltyEnabled || !isLoyaltyEnabled)) ||
          (data?.field_donor_type === 'not_enrolled' &&
            (donor?.isLoyaltyMember === 'No' || donor?.isLoyaltyMember === null) &&
            (isLoyaltyEnabled || !isLoyaltyEnabled)) ||
          (data?.field_donor_type === 'existing_member' &&
            donor?.isLoyaltyMember === 'Yes' &&
            donor?.pdn &&
            (isLoyaltyEnabled || !isLoyaltyEnabled)) ? (
            <BannerContainer
              style={{
                background: `${getCssColorFromDrupalColorType(data?.field_bg_color_1)}`
              }}
            >
              <BannerCurve
                style={{
                  background: `${getCssColorFromDrupalColorType(data?.field_bg_color)}`
                }}
              >
                {isMobileWidth ? (
                  <Container>
                    <Header
                      $textColor={data?.field_title_color}
                      dangerouslySetInnerHTML={sanitizeHtml(data?.field_component_title?.value)}
                    />
                    <Body>
                      <ImageLogo>
                        {getMediaFromDrupalMimeType(
                          data?.field_column_1_icon?.mimetype,
                          data?.field_column_1_icon?.url,
                          data?.field_column_1_icon?.alt
                        )}
                      </ImageLogo>
                      <ImageWrapper>
                        {(data?.field_donor_type === 'existing_member') & (donor?.isLoyaltyMember === 'Yes') && donor?.pdn ? (
                          <AvailablePointsContainer>
                            <RedeemRewardsRender loyaltyPoints={loyaltyPoints} loyaltyActivity={loyaltyActivity} />
                          </AvailablePointsContainer>
                        ) : (
                          <MobileImage>
                            {getMediaFromDrupalMimeType(
                              data?.field_component_image?.mimetype,
                              data?.field_component_image?.url,
                              data?.field_component_image?.alt
                            )}
                          </MobileImage>
                        )}
                      </ImageWrapper>
                      {data?.field_short_description && (
                        <BodyText
                          $textColor={data?.field_title_color}
                          dangerouslySetInnerHTML={sanitizeHtml(data?.field_short_description?.value)}
                        />
                      )}
                      <Button>
                        <RenderPrimaryButton
                          data={data}
                          donor={donor}
                          handleLoyaltyEnrolmentButtonClick={handleLoyaltyEnrolmentButtonClick}
                          redeemPausedMethod={redeemPausedMethod}
                          isMobileWidth={isMobileWidth}
                        />
                      </Button>
                    </Body>
                  </Container>
                ) : (
                  <div className='main-container'>
                    <TextSectionContainer $mediaAlignment={data?.field_alignment}>
                      <TextSection>
                        <Title
                          $textColor={data?.field_title_color}
                          dangerouslySetInnerHTML={sanitizeHtml(data?.field_component_title?.value)}
                        />
                        <Subtitle>
                          {getMediaFromDrupalMimeType(
                            data?.field_column_1_icon?.mimetype,
                            data?.field_column_1_icon?.url,
                            data?.field_column_1_icon?.alt
                          )}
                        </Subtitle>
                        {data?.field_short_description && (
                          <Description
                            $textColor={data?.field_title_color}
                            dangerouslySetInnerHTML={sanitizeHtml(data?.field_short_description?.value)}
                          />
                        )}
                        <EnrollButton>
                          <RenderPrimaryButton
                            data={data}
                            donor={donor}
                            handleLoyaltyEnrolmentButtonClick={handleLoyaltyEnrolmentButtonClick}
                            redeemPausedMethod={redeemPausedMethod}
                          />
                        </EnrollButton>
                      </TextSection>
                      {data.field_donor_type === 'existing_member' && donor?.isLoyaltyMember === 'Yes' && donor?.pdn ? (
                        <RedeemRewardsRender loyaltyPoints={loyaltyPoints} loyaltyActivity={loyaltyActivity} />
                      ) : (
                        <ImageContainer>
                          {getMediaFromDrupalMimeType(
                            data?.field_component_image?.mimetype,
                            data?.field_component_image?.url,
                            data?.field_component_image?.alt
                          )}
                        </ImageContainer>
                      )}
                    </TextSectionContainer>
                  </div>
                )}
              </BannerCurve>
            </BannerContainer>
          ) : null
        )}
      </div>
      {/* redemption process */}
      <CustomModal openModal={openRedeemModal} onClose={() => setOpenRedeemModal(false)}>
        <ContainerOne>
          <RedeemHeaderContainer>
            <RedeemHeader>{t('RedeempOptions')}</RedeemHeader>
          </RedeemHeaderContainer>
          {!getLoyaltyConstantLoading && (
            <>
              <SegmentControl>
                <SegmentButton
                  className={redeemOptions === 'Debit Card' ? 'active' : ''}
                  toggle
                  active={redeemOptions === 'DEBIT_CARD'}
                  onClick={() => setRedeemOptions('DEBIT_CARD')}
                >
                  {t('DebitCard')}
                </SegmentButton>

                <SegmentButton
                  className={redeemOptions === 'Gift Card' ? 'active' : ''}
                  toggle
                  active={redeemOptions === 'GIFT_CARD'}
                  onClick={() => setRedeemOptions('GIFT_CARD')}
                >
                  {t('GiftCard')}
                </SegmentButton>

                <SegmentButton
                  className={redeemOptions === 'Charity' ? 'active' : ''}
                  toggle
                  active={redeemOptions === 'CHARITY'}
                  onClick={() => setRedeemOptions('CHARITY')}
                >
                  {t('CharityCard')}
                </SegmentButton>
              </SegmentControl>
              <RedemptionDescription redeemCondition={!RedeemCondition} textMessage={RedeemMessage} textErrorMessage={RedeemErrorMessage} />
            </>
          )}
          <CalculatorSection>
            <PointsCalculator>
              <strong> {t('PointsTitle')}</strong>
            </PointsCalculator>
            <PointsCount>
              <Label htmlFor='points-input'> {t('PointsText')}</Label>
              <CashCount>
                <strong>{numberWithCommasWithOutDecimals(redeemPoints)}</strong>
              </CashCount>
            </PointsCount>
            <PointsInputContainer>
              <Loyaltypoints>
                <AvailablePoints>
                  {numberWithCommasWithOutDecimals(loyaltyPoints)} {t('pointsAvailable')}
                </AvailablePoints>
              </Loyaltypoints>
              <ButtonContainer>
                <DecrementButton
                  circular
                  basic
                  className='redeem-control-btn'
                  disabled={
                    redeemPoints - redeemConstants?.LOYALTY_INCREMENTAL_POINTS_N < redeemConstants?.LOYALTY_MINIMUM_REDEEM_POINTS_N
                      ? true
                      : false
                  }
                  onClick={() => decrementRedeemPonts()}
                >
                  {redeemPoints - redeemConstants?.LOYALTY_INCREMENTAL_POINTS_N < redeemConstants?.LOYALTY_MINIMUM_REDEEM_POINTS_N ? (
                    <DecrementDisabledIcon />
                  ) : (
                    <DecrementIcon />
                  )}
                </DecrementButton>
                <div className='redeem-btn-container right-btn'>
                  <IncrementButton
                    circular
                    basic
                    disabled={
                      redeemPoints + redeemConstants?.LOYALTY_INCREMENTAL_POINTS_N > redeemConstants?.LOYALTY_MAXIMUM_REDEEM_POINTS_N ||
                      redeemPoints + redeemConstants?.LOYALTY_INCREMENTAL_POINTS_N > loyaltyPoints
                        ? true
                        : false
                    }
                    onClick={() => incrementRedeemPonts()}
                  >
                    {redeemPoints + redeemConstants?.LOYALTY_INCREMENTAL_POINTS_N > redeemConstants?.LOYALTY_MAXIMUM_REDEEM_POINTS_N ||
                    redeemPoints + redeemConstants?.LOYALTY_INCREMENTAL_POINTS_N > loyaltyPoints ? (
                      <IncrementDisabledIcon />
                    ) : (
                      <IncrementIcon />
                    )}
                  </IncrementButton>
                </div>
              </ButtonContainer>
            </PointsInputContainer>
          </CalculatorSection>
          <CashValueDisplay>
            {t('CashValue')}
            <div style={{ fontSize: 56, fontWeight: 'bold' }}>
              <strong>${numberWithCommasWithOutDecimals(cash)}</strong>
            </div>
          </CashValueDisplay>
        </ContainerOne>
        <Modal.Content>
          {!getLoyaltyConstantLoading && (
            <div className='Redeem-points-modal-content'>
              <RedeemButton>
                <PrimaryButton
                  text={t('redeemPoints')}
                  loading={redeemRewardsLoading || redeemRewardsStatusCheckLoading}
                  disabled={
                    loyaltyDetails?.isRedeemProcessing ||
                    loyaltyPoints < redeemConstants?.LOYALTY_MINIMUM_REDEEM_POINTS_N ||
                    RedeemCondition ||
                    !redeemOptions ||
                    redeemRewardsLoading ||
                    redeemRewardsStatusCheckLoading
                  }
                  width={210}
                  height={50}
                  fontSize={16}
                  type='submit'
                  onClick={redeemPointsFormSubmit}
                />
                {redeemRewardsLoading ||
                  (redeemRewardsStatusCheckLoading && (
                    <div>
                      <p>{t('rewardsAreprocessing')}</p>
                    </div>
                  ))}
              </RedeemButton>
            </div>
          )}
        </Modal.Content>
      </CustomModal>
      {/*Not Engough Redeem Points modal */}
      <CustomModal openModal={openNotEnoughPointsModal} onClose={() => setOpenNotEnoughPointsModal(false)}>
        <RedeemPausedContainer>
          <ErrorTitle>
            <strong>{t('ohNoMorePointsAreNeeded')}</strong>
          </ErrorTitle>
          <ErrorMessage>
            <p className='ModalContent'>
              {t('youDonotHaveEnoughpnts', { loyaltyMinPoints })}{' '}
              <NavigateWaysToEarn onClick={() => handleOpen('ways_to_earn')}>
                <strong>{t('waysToEarn')}</strong>
              </NavigateWaysToEarn>
              <CustomModal
                header={
                  <div
                    dangerouslySetInnerHTML={{ __html: data?.field_component_title?.processed }}
                    style={{ ...setTextColor(data?.field_title_color) }}
                  />
                }
                description={
                  <div
                    dangerouslySetInnerHTML={{ __html: data?.field_component_desc?.processed }}
                    style={{ ...setTextColor(data?.field_long_desc_color) }}
                  />
                }
                openModal={openModal}
                onClose={() => setOpenModal(false)}
              >
                {renderModalComponent(data?.field_poup_type)}
              </CustomModal>
            </p>
          </ErrorMessage>
          <div className='scheduleNow'>
            <PrimaryButton text={t('Schedulenow')} width={220} height={52} onClick={() => ScheduleNow()} className='SchedulenowButton' />
          </div>
        </RedeemPausedContainer>
      </CustomModal>

      {/* Redeem Points Success Modal */}
      <CustomModal openModal={openRedeemSuccessModal} onClose={() => setOpenRedeemSuccessModal(false)}>
        <SuccessModalContainer>
          <SuccessIcon>
            <RedemptionSuccessIcon />
          </SuccessIcon>

          <SuccessTitle>
            <strong>{t('success')}</strong>
          </SuccessTitle>
          <Message>{t('youHaveRedeemed')}</Message>
          <Amount>
            <strong>${numberWithCommasWithOutDecimals(cash)}</strong>
          </Amount>
          <MessageText>
            {i18n.language === 'es-MX' ? '' : `$${numberWithCommasWithOutDecimals(cash)}`} {t('DebitMessage', { cash })} {RedemptionMessage}
          </MessageText>
          <PointsInfo>
            {' '}
            {t('YouNowHave')} {numberWithCommasWithOutDecimals(loyaltyPoints)} {t('pointsAvailable')}
          </PointsInfo>
          <RedeemButtonSuccess>
            {' '}
            <PrimaryButton
              text={t('redeemMorePoints')}
              width={242}
              height={56}
              onClick={() => {
                setOpenRedeemSuccessModal(false)
                setOpenRedeemModal(true)
              }}
            />
          </RedeemButtonSuccess>
        </SuccessModalContainer>
      </CustomModal>

      {/* Redeem Points Failure Modal */}
      <CustomModal openModal={openRedeemFailureModal} onClose={() => setOpenRedeemFailureModal(false)}>
        <ErrorModalContainer>
          <SuccessIcon>
            <ErrorIcon />
          </SuccessIcon>
          <SuccessTitle>
            <strong>{t('error')}</strong>
          </SuccessTitle>
          <ErrorInfo>{t('errorInfo')}</ErrorInfo>
          <RedeemButtonSuccess>
            <PrimaryButton
              text={t('tryAgain')}
              width={130}
              height={52}
              onClick={() => {
                setOpenRedeemFailureModal(false)
                setOpenRedeemModal(true)
              }}
            />
          </RedeemButtonSuccess>
        </ErrorModalContainer>
      </CustomModal>
      {/*Redeem Paused modal */}
      <CustomModal openModal={openRedeemPausedModal} onClose={() => setOpenRedeemPausedModal(false)}>
        <RedeemPausedContainer>
          <ErrorTitle>
            <strong>{t('redeemPausedTitle')}</strong>
          </ErrorTitle>
          <ErrorMessage>{t('redeemPausedText')}</ErrorMessage>
          <ErrorMessage>{t('redeemPausedTextDesc')}</ErrorMessage>
          <div className='scheduleNow'>
            <PrimaryButton text={t('Schedulenow')} width={220} height={52} onClick={() => ScheduleNow()} className='SchedulenowButton' />
          </div>
        </RedeemPausedContainer>
      </CustomModal>
      {PostLoginPageData?.map((component, index) => {
        if (component?.type === 'pop_up_component' && component?.field_poup_type === 'loyalty_enrollment') {
          return (
            <LoyaltyModal
              key={index}
              maxWidth={836}
              maxWidthMobile={'87.7%'}
              maxHeight={461}
              maxHeightMobile={690}
              isOpen={(donor?.isLoyaltyMember === 'No' || donor?.isLoyaltyMember === null) && isLoyaltyEnrolmentModalOpen}
              onClose={() => setIsLoyaltyEnrolmentModalOpen(false)}
            >
              <LoyaltyEnrolmentModal
                handleModalSubmit={setIsLoyaltyEnrolmentModalSubmitClicked}
                isLoyaltyEnrolmentModalSubmitClicked={isLoyaltyEnrolmentModalSubmitClicked}
                modalContent={component}
              />
            </LoyaltyModal>
          )
        } else if (component?.type === 'pop_up_component' && component?.field_poup_type === 'loyalty_enrollment_success') {
          return (
            <LoyaltyModal
              key={index}
              maxWidth={836}
              maxWidthMobile={'87.7%'}
              maxHeight={461}
              maxHeightMobile={690}
              modalContent={component}
              isOpen={loyaltyEnrolmentSuccess && isLoyaltyEnrolmentSuccessModalOpen}
              onClose={() => setIsLoyaltyEnrolmentSuccessModalOpen(false)}
            >
              <LoyaltyEnrolmentSuccessModal modalContent={component} />
            </LoyaltyModal>
          )
        }
      })}
    </>
  )
}

const mapStateToProps = ({ state, loyalty, users, publicContent, donors }) => ({
  donor: donors?.donors?.data,
  loyaltyPoints: loyalty?.getLoyaltyDetails?.data?.loyaltyPoints,
  loyaltyActivity: loyalty?.getLoyaltyActivity,
  loyaltyDetails: loyalty?.getLoyaltyDetails?.data,
  loyaltyPoints: loyalty?.getLoyaltyDetails?.data?.loyaltyPoints,
  redeemConstantValues: loyalty?.getLoyaltyConstant,
  getLoyaltyConstantLoading: loyalty?.getLoyaltyConstantLoading,
  redeemRewardsLoading: loyalty?.redeemRewardsLoading,
  redeemRewardsFailure: loyalty?.redeemRewardsFailure,
  redeemRewardsResponse: loyalty?.redeemRewards,
  redeemRewardsStatusCheckLoading: loyalty?.redeemRewardsStatusCheckLoading,
  redeemRewardsStatusCheckFailure: loyalty?.redeemRewardsStatusCheckFailure,
  redeemRewardsStatusCheckResponse: loyalty?.redeemRewardsStatusCheck,
  user: users?.user,
  redeemPaused: publicContent?.getRedeemPausedSuccess,
  vendorRedeemPaused: publicContent?.getVendorRedeemPausedSuccess,
  PostLoginPageData: publicContent?.getPostLoginPageSuccess?.contents
})

const mapDispatchToProps = (dispatch) => ({
  getLoyaltyDetailsByPdn: (pdn) => {
    dispatch(LoyalDonorActions.getLoyaltyDetails(pdn))
  },
  getLoyaltyConstant: () => {
    dispatch(LoyalDonorActions.getLoyaltyConstant())
  },
  redeemRewards: (data) => {
    dispatch(LoyalDonorActions.redeemRewards(data))
  },
  redeemRewardsStatusCheck: (pdn) => {
    dispatch(LoyalDonorActions.redeemRewardsStatusCheck(pdn))
  },
  getRedeemPaused: () => {
    dispatch(PublicContentActions.getRedeemPaused())
  },
  getVendorRedeemPaused: () => {
    dispatch(PublicContentActions.getVendorRedeemPaused())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(BiolifeRewards)
