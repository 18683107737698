import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { setErrorMessageTitle } from '../../../Helpers/Utils'

import {
  AppointmentSlotsGrid,
  AppointmentSlot,
  AvailableSlotTime,
  AvailableSlotTimeLabel,
  AppointmentSelectionErrorContainer,
  LoadingState
} from './styled'
import LoaderBar from '../../Loaders/LoaderBar'

const AppointmentHours = ({ appointment, handleAppointment, availableSlots = [], centerSlotsFailure, centerSlotsLoading = false }) => {
  const { t, i18n } = useTranslation('AppointmentHours')
  const [selectedHour, setSelectedHours] = useState(null)
  const [changes, setChanges] = useState(false)
  const handleHoursChange = (e, { value }) => {
    sessionStorage.removeItem('APPOINTMENT_LOCATION')
    sessionStorage.removeItem('SELECTED_LANGUAGE')
    let newAppointment = {
      ...appointment,
      startTime: value?.appointmentSlotTime,
      startDateTime: value?.appointmentSlotDateTime
    }
    setSelectedHours(value?.appointmentSlotTime)
    handleAppointment(newAppointment)
    sessionStorage?.setItem('APPOINTMENT_LOCATION', JSON.stringify(newAppointment))
    sessionStorage?.setItem('SELECTED_LANGUAGE', i18n.language)
  }

  useEffect(() => {
    if (appointment.startTime) {
      sessionStorage.removeItem('APPOINTMENT_LOCATION')
      sessionStorage.removeItem('SELECTED_LANGUAGE')
      setSelectedHours(appointment.startTime)
      sessionStorage?.setItem('APPOINTMENT_LOCATION', JSON.stringify(appointment))
      sessionStorage?.setItem('SELECTED_LANGUAGE', i18n.language)
    } else {
      setSelectedHours(null)
    }
    if (appointment.appointmentType) {
      setChanges(true)
    }
  }, [appointment])

  if (centerSlotsLoading) {
    return (
      <LoadingState>
        <LoaderBar />
      </LoadingState>
    )
  }

  if (appointment && appointment.appointmentType === '') {
    return (
      <AppointmentSelectionErrorContainer>
        <h5>
          <strong>{t('pleaseSelectAnAppointmentType')}</strong>
        </h5>
      </AppointmentSelectionErrorContainer>
    )
  }

  return (
    <AppointmentSlotsGrid $availableSlots={availableSlots?.length}>
      {availableSlots &&
        availableSlots.length > 0 &&
        availableSlots.map((slot, index) => (
          <AppointmentSlot $selectedSlot={selectedHour === slot?.appointmentSlotTime}>
            <AvailableSlotTime
              type='radio'
              id={slot?.appointmentSlotTime}
              value={slot?.appointmentSlotTime}
              name='hours'
              onChange={(e) => handleHoursChange(e, { value: slot })}
              checked={selectedHour === slot?.appointmentSlotTime}
            />
            <AvailableSlotTimeLabel for={slot?.appointmentSlotTime}>{slot?.appointmentSlotTime}</AvailableSlotTimeLabel>
          </AppointmentSlot>
        ))}
    </AppointmentSlotsGrid>
  )
}

export default AppointmentHours
