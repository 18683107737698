import React, { useState, useEffect, useRef } from 'react'
import chatbotActions from '@takedapdt/biolife-core/src/Stores/Chatbot/Actions'
import { useScreenSize } from '../../Contexts/ResponsiveContextProvider'
// import chatbotActions from '../../../../biolife-core/src/Stores/Chatbot/Actions'
import { useDispatch, useSelector } from 'react-redux'
import {
  ChatbotMainContent,
  BlueDot,
  PopupImageContainer,
  PopupImage,
  MainChatbotContainer,
  ChatBox,
  StyledSpan,
  ChatbotBody,
  ModalContainer
} from './chatbot.styled'
import ChatIcon from '../../../assets/media/icons/OrangeChatbotIcon1.svg'
import ChatBotHeader from './Header'
import MessageArea from './MessageArea'
import BoxedModal from './Components/BoxedModal'
import CloseModalContent from './Modals/EndChatPopup'
import Inactivity5min from './Modals/Inactivity5min'
import Inactivity10min from './Modals/Inactivity10min'
import { throttle, debounce } from 'lodash'

const Chatbot = React.forwardRef((props, chatbotWindow) => {
  const {
    isAuthenticatedUser,
    handleIsChatbotOpen,
    outsideChatbot,
    handleOutsideChatbot,
    showInactivityPopup,
    handleInactivityPopup,
    chatbotMinimized,
    handleChatbotMinized
  } = props
  const { innerWidth, innerHeight } = window
  const dispatch = useDispatch()
  const { isMobileWidth } = useScreenSize()
  const openChatbot = useSelector((state) => state.chatbot.openChatbot)
  const showHideChatbot = useSelector((state) => state.chatbot.showChatbot)
  const interval1 = useRef()
  const interval2 = useRef()
  const currentSessionID = useRef(null)
  const currentIntent = useRef('')
  // For second timer we just start the same timoutValue again after the firstTimout
  const timoutValue = 5 * 60 * 1000
  const [afterInactivityContinued, setAfterInactivityContinued] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState('English')
  const [messagesArr, setMessagesArr] = useState([])
  const [resetChatbot, setResetChatbot] = useState(false)
  const [chatOpen, setChatOpen] = useState(false || openChatbot)
  const [openEndChatPopup, setEndChatPopup] = useState(false)
  const [commAlreadyStarted, setCommAlreadyStarted] = useState(false)
  // inputText is given through fetchMessage function
  // chatStarted is a flag boolean which is used to create a chatSessionId at backend
  const [chatbotData, setChatbotData] = useState({
    localeId: selectedLanguage === 'Español' ? 'es_US' : 'en_US',
    chatStarted: false,
    chatEnded: false,
    sessionTimout: false
  })
  const changesOnSessionTimout = () => {
    clearTimeout(interval1.current)
    clearTimeout(interval2.current)
    interval1.current = null
    interval2.current = null
    localStorage.removeItem('chatbotData')
    sendChatEndedMessage('', { ...chatbotData, sessionTimout: true })
  }

  useEffect(() => {
    if (messagesArr && !messagesArr.length && localStorage.getItem('chatbotData') && localStorage.getItem('chatbotData').length) {
      // chatStarted once true will always be false because chatSessionID is true only once at start to create chatSession
      // So if bot is getting data from localStorage we know chat has started and thus false
      setChatbotData((prev) => {
        return { ...prev, chatStarted: false }
      })
    }
  }, [])

  useEffect(() => {
    if (chatOpen && outsideChatbot && isMobileWidth) {
      setChatOpen(false)
      handleOutsideChatbot(false)
    }
  }, [outsideChatbot])

  useEffect(() => {
    if (!isAuthenticatedUser || !Object.keys(isAuthenticatedUser).length) {
      resetBotState()
    }
  }, [isAuthenticatedUser])

  useEffect(() => {
    if (chatOpen) {
      handleIsChatbotOpen(true)
    } else {
      handleIsChatbotOpen(false)
    }
    if (chatOpen && commAlreadyStarted) {
      handleChatbotMinized(true)
    }
  }, [chatOpen, commAlreadyStarted])

  useEffect(() => {
    if (!chatbotMinimized) {
      clearTimeout(interval1.current)
      clearTimeout(interval2.current)
      interval1.current = null
      interval2.current = null
    }
  }, [chatbotMinimized])

  useEffect(() => {
    if (showInactivityPopup === 0 && afterInactivityContinued) {
      resetTimeouts(chatbotMinimized)
    }
  }, [showInactivityPopup, afterInactivityContinued])

  useEffect(() => {
    if (commAlreadyStarted) {
      interval1.current = setTimeout(() => {
        handleInactivityPopup(5)
        interval2.current = setTimeout(() => {
          handleInactivityPopup(10)
          changesOnSessionTimout()
        }, timoutValue)
      }, timoutValue)
    }
  }, [commAlreadyStarted])

  const resetTimeouts = (chatbotMinimized) => {
    if (!commAlreadyStarted) {
      clearTimeout(interval1.current)
      clearTimeout(interval2.current)
      return
    }
    if (showInactivityPopup) {
      // Dont want to reset Timer if the inactivity popup has appeared
      return
    }
    clearTimeout(interval1.current)
    clearTimeout(interval2.current)

    interval1.current = setTimeout(() => {
      handleInactivityPopup(5)
      interval2.current = setTimeout(() => {
        handleInactivityPopup(10)
        changesOnSessionTimout()
        clearTimeout(interval1.current)
        clearTimeout(interval2.current)
      }, timoutValue)
    }, timoutValue)
  }

  useEffect(() => {
    setChatbotData((prev) => {
      return {
        ...prev,
        localeId: selectedLanguage === 'Español' ? 'es_US' : 'en_US'
      }
    })
  }, [selectedLanguage])

  const changeChatbotData = (value) => {
    setChatbotData(value)
  }

  const changeChatbotSelectedLanguage = (value) => {
    // console.log('value of language Selected>>', value, value === 'Español' ? 'es_US' : 'en_US')
    setSelectedLanguage(value)
  }

  const changeCommAlreadyStarted = () => {
    setCommAlreadyStarted((prev) => !prev)
  }

  const openCloseChatbotpopup = () => {
    // Open End Chatbot pop up
    if (!commAlreadyStarted) {
      // Not Showing popup if the user has not started the conversation
      closeChatbotAndReset()
      return
    }
    setEndChatPopup(true)
  }

  const CrossCloseChatbotpopup = () => {
    // Open End Chatbot pop up
    setEndChatPopup(false)
  }

  const toggle = (e) => {
    setChatOpen(!chatOpen)
    if (chatOpen && resetChatbot) {
      setResetChatbot(false)
    }
  }

  const resetBotState = (value) => {
    setResetChatbot(value)
    changeChatbotSelectedLanguage('English')
    handleChatbotMinized(false)
    showInactivityPopup && handleInactivityPopup(0)
    resetTimeouts(chatbotMinimized)
    if (commAlreadyStarted) {
      sendChatEndedMessage('')
      setCommAlreadyStarted(false)
      localStorage.removeItem('chatbotData')
    }
  }

  const closeChatbotAndReset = () => {
    toggle()
    CrossCloseChatbotpopup()
    resetBotState(true)
    if (commAlreadyStarted) {
      sendChatEndedMessage('')
      handleChatbotMinized(false)
      localStorage.removeItem('chatbotData')
    }
  }

  const sendChatEndedMessage = (inputText, extraData = {}) => {
    let data = { inputText, ...chatbotData, ...extraData, chatEnded: true }
    dispatch(chatbotActions.getChatbotResponse(data))
  }

  const messageArrSetter = (value) => {
    setMessagesArr(value)
  }

  const createLoadingBotMessage = () => {
    setMessagesArr((prev) => {
      return [
        ...prev,
        {
          type: 'loading',
          messageFrom: 'bot',
          message: [
            {
              content: '...',
              contentType: 'PlainText'
            }
          ]
        }
      ]
    })
  }

  const createBotText = (message) => {
    if (!Array.isArray(message)) return
    const newData = messagesArr.slice(0, messagesArr.length - 1)
    setMessagesArr((prev) => {
      return [
        ...newData,
        {
          messageFrom: 'bot',
          message
        }
      ]
    })
  }

  const createUserText = (userMessage) => {
    setMessagesArr((prev) => {
      return [
        ...prev,
        {
          messageFrom: 'user',
          message: userMessage
        }
      ]
    })
  }

  const resumeChatInactivity = () => {
    handleInactivityPopup(0)
    setAfterInactivityContinued(true)
  }

  return (
    <MainChatbotContainer $showHideChatbot={showHideChatbot} $chatOpen={chatOpen} ref={chatbotWindow}>
      {chatOpen && (
        <ChatBox
          $innerWidth={innerWidth}
          $innerHeight={innerHeight}
          onClick={throttle(() => {
            resetTimeouts()
          }, 50)}
          onMouseMove={throttle(() => {
            resetTimeouts()
          }, 50)}
          onKeyPress={debounce(() => {
            resetTimeouts()
          }, 50)}
        >
          <ChatBotHeader
            openCloseChatbotpopup={openCloseChatbotpopup}
            toggleChatbot={toggle}
            text='Hey There!'
            resetBotState={resetBotState}
          />
          <ModalContainer>
            {/* <ChatbotBody> */}
            <MessageArea
              messagesArr={messagesArr}
              messageArrSetter={messageArrSetter}
              createBotText={createBotText}
              createUserText={createUserText}
              createLoadingBotMessage={createLoadingBotMessage}
              commAlreadyStarted={commAlreadyStarted}
              changeCommAlreadyStarted={changeCommAlreadyStarted}
              resetChatbot={resetChatbot}
              resetBotState={resetBotState}
              changeChatbotData={changeChatbotData}
              chatbotData={chatbotData}
              changeChatbotSelectedLanguage={changeChatbotSelectedLanguage}
              selectedLanguage={selectedLanguage}
              currentSessionID={currentSessionID}
              currentIntent={currentIntent}
              resetTimeouts={resetTimeouts}
            />
            {/* </ChatbotBody> */}
            <BoxedModal
              maxWidth={'37rem'}
              maxHeight={'40rem'}
              isOpen={openEndChatPopup}
              closeFunction={CrossCloseChatbotpopup}
              modalContainerStyle={{ bottom: '10%' }}
            >
              <CloseModalContent closeFunction={CrossCloseChatbotpopup} closeChatbotAndReset={closeChatbotAndReset} />
            </BoxedModal>
            <BoxedModal
              maxWidth={'37rem'}
              maxHeight={'40rem'}
              isOpen={showInactivityPopup === 5}
              closeFunction={resumeChatInactivity}
              modalContainerStyle={{ bottom: '10%' }}
            >
              <Inactivity5min
                resumeChat={resumeChatInactivity}
                selectedLanguage={selectedLanguage}
                resetChat={() => {
                  handleInactivityPopup(0)
                  handleChatbotMinized(false)
                  resetBotState(true)
                  resetTimeouts(chatbotMinimized)
                }}
              />
            </BoxedModal>
            <BoxedModal
              maxWidth={'37rem'}
              maxHeight={'40rem'}
              isOpen={showInactivityPopup === 10}
              noCloseIcon={true}
              modalContainerStyle={{ bottom: '10%' }}
            >
              <Inactivity10min
                resetChat={() => {
                  handleInactivityPopup(0)
                  handleChatbotMinized(false)
                  resetBotState(true)
                  resetTimeouts(chatbotMinimized)
                }}
                selectedLanguage={selectedLanguage}
              />
            </BoxedModal>
          </ModalContainer>
        </ChatBox>
      )}
      {!chatOpen && (
        <ChatbotMainContent>
          <PopupImageContainer onClick={toggle}>
            <PopupImage src={ChatIcon} />
            <StyledSpan id='askUs'>Ask Us</StyledSpan>
          </PopupImageContainer>
          {showInactivityPopup ? <BlueDot /> : <></>}
        </ChatbotMainContent>
      )}
    </MainChatbotContainer>
  )
})

export default Chatbot
